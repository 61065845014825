import React from 'react';
import apiConfig from '../../../../config/api-config';

function NoSignalVideo() {
  const videoSrc = `https://${apiConfig.videoCDN}/public-video-assets/public/waiting_room_no_signal.mp4`;
  return (
    <div className="waiting-room-no-signal">
      <video data-testid="no-signal-video" autoPlay muted loop playsInline>
        <source src={videoSrc} type="video/mp4" />
      </video>
    </div>
  );
}
export default NoSignalVideo;
