/* eslint-disable consistent-return */
// this is a copy of simpleSelector function from videojs/http-streaming
// which we need to use in our custom selectPlaylist function
// but with different parameters.
// Original code is here:
// https://github.com/videojs/http-streaming/blob/v2.14.3/src/playlist-selectors.js

import { logger } from '../../../utils/logger';

const BANDWIDTH_VARIANCE = 1.2;

const stableSort = function stableSort(array, sortFn) {
  const newArray = array.slice();

  array.sort((left, right) => {
    const cmp = sortFn(left, right);

    if (cmp === 0) {
      return newArray.indexOf(left) - newArray.indexOf(right);
    }
    return cmp;
  });
};

function isIncompatible(playlist) {
  return playlist.excludeUntil && playlist.excludeUntil === Infinity;
}

function isExcluded(playlist) {
  return playlist.excludeUntil && playlist.excludeUntil > Date.now();
}

function isEnabled(playlist) {
  const excluded = isExcluded(playlist);
  return !playlist.disabled && !excluded;
}

function isDisabled(playlist) {
  return playlist.disabled;
}

function representationToString(representation) {
  if (!representation || !representation.playlist) {
    return '';
  }
  const { playlist } = representation;

  return JSON.stringify({
    id: playlist.id,
    bandwidth: representation.bandwidth,
    width: representation.width,
    height: representation.height,
    codecs: (playlist.attributes && playlist.attributes.CODECS) || '',
  });
}

function log(...args) {
  logger.debug(...args);
}

export const playlistSelector = function playlistSelector(
  master,
  playerBandwidth,
  playerWidth,
  playerHeight,
  limitRenditionByPlayerDimensions,
  masterPlaylistController,
) {
  // If we end up getting called before `master` is available, exit early
  if (!master) {
    return;
  }

  const options = {
    bandwidth: playerBandwidth,
    width: playerWidth,
    height: playerHeight,
    limitRenditionByPlayerDimensions,
  };

  const { playlists } = master;

  // convert the playlists to an intermediary representation to make comparisons easier
  let sortedPlaylistReps = playlists.map((playlist) => {
    let bandwidth;
    const width = playlist.attributes
      && playlist.attributes.RESOLUTION
      && playlist.attributes.RESOLUTION.width;
    const height = playlist.attributes
      && playlist.attributes.RESOLUTION
      && playlist.attributes.RESOLUTION.height;

    bandwidth = playlist.attributes && playlist.attributes.BANDWIDTH;

    bandwidth = bandwidth || window.Number.MAX_VALUE;

    return {
      bandwidth,
      width,
      height,
      playlist,
    };
  });

  stableSort(sortedPlaylistReps, (left, right) => left.bandwidth - right.bandwidth);

  // filter out any playlists that have been excluded due to
  // incompatible configurations
  sortedPlaylistReps = sortedPlaylistReps.filter((rep) => !isIncompatible(rep.playlist));

  // filter out any playlists that have been disabled manually through the representations
  // api or blacklisted temporarily due to playback errors.
  let enabledPlaylistReps = sortedPlaylistReps.filter((rep) => isEnabled(rep.playlist));

  if (!enabledPlaylistReps.length) {
    // if there are no enabled playlists, then they have all been blacklisted or disabled
    // by the user through the representations api. In this case, ignore blacklisting and
    // fallback to what the user wants by using playlists the user has not disabled.
    enabledPlaylistReps = sortedPlaylistReps.filter((rep) => !isDisabled(rep.playlist));
  }

  // filter out any variant that has greater effective bitrate
  // than the current estimated bandwidth
  const bandwidthPlaylistReps = enabledPlaylistReps.filter(
    (rep) => rep.bandwidth * BANDWIDTH_VARIANCE < playerBandwidth,
  );

  let highestRemainingBandwidthRep = bandwidthPlaylistReps[bandwidthPlaylistReps.length - 1];

  // get all of the renditions with the same (highest) bandwidth
  // and then taking the very first element
  const bandwidthBestRep = bandwidthPlaylistReps.filter(
    (rep) => rep.bandwidth === highestRemainingBandwidthRep.bandwidth,
  )[0];

  // if we're not going to limit renditions by player size, make an early decision.
  if (limitRenditionByPlayerDimensions === false) {
    const chosenRep = (
      bandwidthBestRep
      || enabledPlaylistReps[0]
      || sortedPlaylistReps[0]
    );

    if (chosenRep && chosenRep.playlist) {
      let type = 'sortedPlaylistReps';

      if (bandwidthBestRep) {
        type = 'bandwidthBestRep';
      }
      if (enabledPlaylistReps[0]) {
        type = 'enabledPlaylistReps';
      }
      log(`choosing ${representationToString(chosenRep)} using ${type} with options`, options);

      return chosenRep.playlist;
    }

    log('could not choose a playlist with options', options);
    return null;
  }

  // filter out playlists without resolution information
  const haveResolution = bandwidthPlaylistReps.filter((rep) => rep.width && rep.height);

  // sort variants by resolution
  stableSort(haveResolution, (left, right) => left.width - right.width);

  // if we have the exact resolution as the player use it
  const resolutionBestRepList = haveResolution.filter(
    (rep) => rep.width === playerWidth && rep.height === playerHeight,
  );

  highestRemainingBandwidthRep = resolutionBestRepList[resolutionBestRepList.length - 1];
  // ensure that we pick the highest bandwidth variant that have exact resolution
  const resolutionBestRep = resolutionBestRepList.filter(
    (rep) => rep.bandwidth === highestRemainingBandwidthRep.bandwidth,
  )[0];

  let resolutionPlusOneList;
  let resolutionPlusOneSmallest;
  let resolutionPlusOneRep;

  // find the smallest variant that is larger than the player
  // if there is no match of exact resolution
  if (!resolutionBestRep) {
    resolutionPlusOneList = haveResolution.filter(
      (rep) => rep.width > playerWidth || rep.height > playerHeight,
    );

    // find all the variants have the same smallest resolution
    resolutionPlusOneSmallest = resolutionPlusOneList.filter(
      (rep) => rep.width === resolutionPlusOneList[0].width
      && rep.height === resolutionPlusOneList[0].height,
    );

    // ensure that we also pick the highest bandwidth variant that
    // is just-larger-than the video player
    highestRemainingBandwidthRep = resolutionPlusOneSmallest[resolutionPlusOneSmallest.length - 1];
    // eslint-disable-next-line prefer-destructuring
    resolutionPlusOneRep = resolutionPlusOneSmallest.filter(
      (rep) => rep.bandwidth === highestRemainingBandwidthRep.bandwidth,
    )[0];
  }

  let leastPixelDiffRep;

  // If this selector proves to be better than others,
  // resolutionPlusOneRep and resolutionBestRep and all
  // the code involving them should be removed.
  if (masterPlaylistController.experimentalLeastPixelDiffSelector) {
    // find the variant that is closest to the player's pixel size
    const leastPixelDiffList = haveResolution.map((rep) => {
      // eslint-disable-next-line no-param-reassign
      rep.pixelDiff = Math.abs(rep.width - playerWidth) + Math.abs(rep.height - playerHeight);
      return rep;
    });

    // get the highest bandwidth, closest resolution playlist
    stableSort(leastPixelDiffList, (left, right) => {
      // sort by highest bandwidth if pixelDiff is the same
      if (left.pixelDiff === right.pixelDiff) {
        return right.bandwidth - left.bandwidth;
      }

      return left.pixelDiff - right.pixelDiff;
    });

    // eslint-disable-next-line prefer-destructuring
    leastPixelDiffRep = leastPixelDiffList[0];
  }

  // fallback chain of variants
  const chosenRep = (
    leastPixelDiffRep
    || resolutionPlusOneRep
    || resolutionBestRep
    || bandwidthBestRep
    || enabledPlaylistReps[0]
    || sortedPlaylistReps[0]
  );

  if (chosenRep && chosenRep.playlist) {
    let type = 'sortedPlaylistReps';

    if (leastPixelDiffRep) {
      type = 'leastPixelDiffRep';
    } else if (resolutionPlusOneRep) {
      type = 'resolutionPlusOneRep';
    } else if (resolutionBestRep) {
      type = 'resolutionBestRep';
    } else if (bandwidthBestRep) {
      type = 'bandwidthBestRep';
    } else if (enabledPlaylistReps[0]) {
      type = 'enabledPlaylistReps';
    }

    log(`choosing ${representationToString(chosenRep)} using ${type} with options`, options);
    return chosenRep.playlist;
  }
  log('could not choose a playlist with options', options);
  return null;
};
