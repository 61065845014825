import { React } from 'react';
import { Link } from 'react-router-dom';
import { bool, func, string } from 'prop-types';

import './FoldableLinks.scss';

function FoldableLinks({ pathMainPart, isOpen, toggleSidebar }) {
  if (pathMainPart === 'episode') return null;
  return (
    <>
      <div className={`nav-links-container ${isOpen ? 'opened' : null}`}>
        <Link className="btn btn-link" to="/about" relative="path">about</Link>
        <Link className="btn btn-link" to="/faq" relative="path">faq</Link>
        <button type="button" aria-label="close" className="btn btn-close" onClick={toggleSidebar} />
      </div>
      <div className="navigation-menu-overlay" onClick={toggleSidebar} aria-hidden="true" />
    </>
  );
}

FoldableLinks.propTypes = {
  pathMainPart: string,
  isOpen: bool,
  toggleSidebar: func,
};

FoldableLinks.defaultProps = {
  pathMainPart: '',
  isOpen: false,
  toggleSidebar: () => {},
};

export default FoldableLinks;
