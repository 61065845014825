import React from 'react';
import PropTypes from 'prop-types';

import { formatSrcSet } from '../../utils/format-src-set';

function Image({ src, srcset, alt, ...props }) {
  const formattedSrcset = formatSrcSet(srcset);

  return (
    <img
      src={src}
      srcSet={formattedSrcset}
      alt={alt}
      loading="lazy"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
export default Image;

Image.propTypes = {
  src: PropTypes.string.isRequired,
  srcset: PropTypes.arrayOf(
    PropTypes.exact({
      imageUrl: PropTypes.string.isRequired,
      width: PropTypes.number,
    }),
  ),
  alt: PropTypes.string.isRequired,
};

Image.defaultProps = {
  srcset: [],
};
