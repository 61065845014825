import controlBarConfig from '../../../config/control-bar-config';

export default {
  height: 240,
  enableSourceset: true,
  // loadingSpinner: false,
  userActions: {
    doubleClick: false,
  },
  controlBar: controlBarConfig,
  playsinline: true,
};
