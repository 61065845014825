import React, { memo } from 'react';
import { arrayOf, func, shape, string } from 'prop-types';

import SecondaryVideo from '../SecondaryVideo';

function SecondaryVideos({ configs, onReady }) {
  return configs.map((config, i) => (
    <SecondaryVideo
      key={config.roomId}
      roomId={config.roomId}
      onReady={onReady}
      index={i}
    />
  ));
}

export default memo(SecondaryVideos);

SecondaryVideos.propTypes = {
  configs: arrayOf(shape({
    roomId: string,
  })).isRequired,
  onReady: func.isRequired,
};
