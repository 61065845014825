import React from 'react';

import { socialLinks } from '../../../constants/socialLinks';
import Image from '../../../components/Image';
import './HomeFooter.scss';

const merchUrl = 'https://www.onthestage.tickets/show/foul-play/6400d44980804f0e3e95a67d/merchandise';
const socialItemsConfig = [
  {
    href: socialLinks.DISCORD,
    icon: '/images/footer-papers/discord-icon.png',
    alt: 'Discord',
  },
  {
    href: socialLinks.INSTAGRAM,
    icon: '/images/footer-papers/instagram-icon.png',
    alt: 'Instagram',
  },
  {
    href: socialLinks.TIKTOK,
    icon: '/images/footer-papers/tiktok-icon.png',
    alt: 'TikTok',
  },
];

function HomeFooter() {
  const socialItems = socialItemsConfig.map((socialItem) => (
    <a
      key={socialItem.alt}
      className="paper center"
      href={socialItem.href}
      target="_blank"
      rel="noreferrer"
    >
      <Image
        className="network-icon"
        src={socialItem.icon}
        alt={socialItem.alt}
      />
    </a>
  ));

  return (
    <footer>
      <div className="buy-merch">
        <a href={merchUrl} target="_blank" rel="noreferrer">
          Buy merch
        </a>
      </div>
      <div className="social-networks">{socialItems}</div>
    </footer>
  );
}

export default HomeFooter;
