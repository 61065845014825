import React from 'react';
import { Link } from 'react-router-dom';
import { bool, string } from 'prop-types';

import { socialLinks } from '../../constants/socialLinks';
import Evidence from '../EpisodePlayer/components/Evidence';
import MuteButton from '../EpisodePlayer/components/MuteButton';
import HowToPlayButton from '../HowToPlay';

import './EpisodePlayerTopMenu.scss';

function EpisodePlayerTopMenu({ showEvidenceButton, showMuteButton, episodeId }) {
  return (
    <div className="episode-watch-top-menu">
      <div className="episode-watch-top-menu-left">
        <Link
          className="btn btn-burger"
          to="../"
          relative="path"
          aria-label="home"
        />
      </div>
      <div className="episode-watch-top-menu-right">
        {showMuteButton && <MuteButton />}
        {showEvidenceButton && <Evidence episodeId={episodeId} />}
        <a className="btn btn-outline-secondary btn-discord" href={socialLinks.DISCORD} target="_blank" rel="noreferrer">
          <span className="title">Join Discord</span>
          <img className="icon" src="/images/icons/discord-icon.svg" alt="Discord" />
        </a>
        <HowToPlayButton className="btn btn-outline-secondary" />
      </div>
    </div>
  );
}

export default EpisodePlayerTopMenu;

EpisodePlayerTopMenu.propTypes = {
  showEvidenceButton: bool,
  showMuteButton: bool,
  episodeId: string.isRequired,
};

EpisodePlayerTopMenu.defaultProps = {
  showEvidenceButton: false,
  showMuteButton: false,
};
