import React from 'react';
import { func } from 'prop-types';

import controlBarConfig from '../../../../config/control-bar-config';
import apiConfig from '../../../../config/api-config';

import FullscreenPopup from '../../../FullscreenPopup';
import HlsVideoPlayer from '../../../HlsVideoPlayer';

const videoOptions = {
  enableSourceset: true,
  loop: true,
  controlBar: controlBarConfig,
  loadingSpinner: false,
  controls: true,
  bigPlayButton: true,
  sources: [{
    src: `https://${apiConfig.videoCDN}/public-video-assets/how_to_play/16_9/playlist.m3u8`,
    type: 'application/x-mpegURL',
  }],
};

function HowToPlayPopup({ onClose }) {
  return (
    <FullscreenPopup onClose={onClose} className="how-to-play-popup">
      <HlsVideoPlayer options={videoOptions} />
    </FullscreenPopup>
  );
}

export default HowToPlayPopup;

HowToPlayPopup.propTypes = {
  onClose: func.isRequired,
};
