import SyncWithPlayerStrategy from '../SyncWithPlayerStrategy/SyncWithPlayerStrategy';
import Events from '../../constants/events';
import { PUSH_FORWARD_S } from '../../config/video-sync-config';
import { logger } from '../../../../utils/logger';

export default class SyncWithPlayerOnDemandStrategy extends SyncWithPlayerStrategy {
  init(player) {
    if (!player || player.isDisposed()) {
      logger.error('Trying to init sync strategy for disposed player. Abort operation');
      return;
    }

    super.init(player);

    if (!this.playerToSyncWith || this.playerToSyncWith.isDisposed()) {
      this.player.log('Trying to sync with disposed player on strategy init. Abort operation');
      return;
    }

    this.playHandler = this.onPlay.bind(this);
    this.pauseHandler = this.onPause.bind(this);
    this.caughtUpHandler = this.onCaughtUp.bind(this);

    this.playerToSyncWith.on(Events.PLAY, this.playHandler);
    this.playerToSyncWith.on(Events.PAUSE, this.pauseHandler);
    this.playerToSyncWith.on(Events.CAUGHT_UP, this.caughtUpHandler);

    this.player.setCurrentTime(this.getTimeToSyncWith());
    this.player.setIsPlaying(!this.playerToSyncWith.isPaused());
    this.onCaughtUp();
  }

  onPlay() {
    this.player.setIsPlaying(true);
  }

  onPause() {
    this.player.setIsPlaying(false);
  }

  onCaughtUp() {
    if (this.playerToSyncWith.isPaused() && this.playerToSyncWith.getCurrentTime() === 0) {
      this.player.trigger(Events.CAUGHT_UP);
    }
  }

  onTimeUpdate() {
    if (this.playerToSyncWith.isDisposed()) return;
    if (this.player.isPaused() && this.playerToSyncWith.isPaused()) return;
    super.onTimeUpdate();
  }

  updateCurrentTime(value) {
    const currentTime = this.player.getCurrentTime();
    const timeDiff = value - currentTime;
    const isFuture = timeDiff > 0;

    if (!isFuture) {
      this.player.log('PLAYER IS NOW AHEAD!');
      // we are ahead, do nothing, just wait while the elapsed time will be the same
      if (this.isSeekingTimeoutActive) {
        return;
      }

      const maxAllowedTimeDiff = PUSH_FORWARD_S + this.nextOffsetForPlayerStart;

      if (Math.abs(timeDiff) > maxAllowedTimeDiff) {
        this.player.log('REWIND BACKWARD TO', value);
        this.pushForwardAndSynchronize(value);
        return;
      }

      this.player.pause();

      this.player.log('START WAITING FOR SYNCHRONIZED TIME');
      this.setSeekingTimeout(currentTime);
      return;
    }

    this.pushForwardAndSynchronize(value);
  }

  destroy() {
    super.destroy();
    this.playerToSyncWith.off(Events.PLAY, this.playHandler);
    this.playerToSyncWith.off(Events.PAUSE, this.pauseHandler);
    this.playerToSyncWith = null;
  }
}
