import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useInterval } from 'react-use';
import Ticket from '../../../api/services/Ticket';

const POLLING_DELAY_INTERVAL = 60_000; // 1 minute

export function usePolling(episodeId, seasonId) {
  const isPollingInProgress = useSelector((state) => state.polling.isInProgress);

  const [isMultipleWatching, setIsMultipleWatching] = useState(false);
  const [isTicketSessionEnded, setIsTicketSessionEnded] = useState(false);
  const [pollingDelay, setPollingDelay] = useState(null);

  const poll = async () => {
    const result = await Ticket.poll(episodeId, seasonId);

    if (result?.status === 417) {
      setIsTicketSessionEnded(true);
      setPollingDelay(null);
      return;
    }

    if (!result.isValid) {
      setIsMultipleWatching(true);
      setPollingDelay(null);
    }
  };

  useInterval(() => {
    poll();
  }, pollingDelay);

  useEffect(() => {
    if (isPollingInProgress) {
      setPollingDelay(POLLING_DELAY_INTERVAL);
    } else {
      setPollingDelay(null);
    }

    return () => {
      setPollingDelay(null);
    };
  }, [isPollingInProgress]);

  return { isMultipleWatching, isTicketSessionEnded };
}
