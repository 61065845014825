import Events from '../constants/events';
import NoSyncStrategy from '../strategies/NoSyncStrategy';
import { logger } from '../../../utils/logger';

export default class CustomPlayer {
  constructor(player, playerId, enableLogging = false) {
    this.log('CREATE CUSTOM PLAYER', playerId);
    this.playerId = playerId;
    this.originalPlayer = player;
    this.isSeeking = false;
    this.isCaughtUp = false;
    this.enableLogging = enableLogging;
    this.setSyncStrategy(new NoSyncStrategy());

    this.volumeDown();

    this.on(Events.SEEKING, () => {
      this.log('on SEEKING');
      this.isSeeking = true;
    });

    this.on(Events.SEEKED, () => {
      this.log('on SEEKED');
      this.isSeeking = false;
    });

    this.on('ended', () => {
      this.log('on ENDED');
    });

    this.on('error', (event) => {
      this.log('on ERROR', event);
    });

    this.on('suspend', (event) => {
      this.log('on SUSPEND', event);
    });

    this.on('abort', (event) => {
      this.log('on ABORT', event);
    });

    this.on('stalled', (event) => {
      this.log('on STALLED', event);
    });

    this.on('waiting', (event) => {
      this.log('on WAITING', event);
    });

    this.on(Events.CAUGHT_UP, () => {
      this.isCaughtUp = true;
    });
  }

  setSyncStrategy(strategy) {
    this.destroySyncStrategy();
    this.log('SET SYNC STRATEGY', strategy);
    this.syncStrategy = strategy;
    this.syncStrategy.init(this);
  }

  destroySyncStrategy() {
    this.log('DESTROY SYNC STRATEGY', this.syncStrategy);
    if (this.syncStrategy) {
      this.syncStrategy.destroy();
      this.syncStrategy = null;
    }
  }

  isPaused() {
    return this.originalPlayer.paused;
  }

  // from button control
  setIsPlaying(isPlaying) {
    this.log('setIsPlaying from button control: ', isPlaying);
    this.log('IS PLAYING NOW: ', this.isPlaying);
    if (this.isPlaying === isPlaying) return;

    this.isPlaying = isPlaying;
    if (this.isPlaying) {
      // the unmuted player can be started
      // only if it's the result of user action,
      // so we should start playback once the user clicked the button
      // https://webkit.org/blog/6784/new-video-policies-for-ios/
      this.waitForMetadata(() => {
        this.syncStrategy.handleTimeUpdate();
        this.play();
      });
    } else this.pause();
  }

  play() {
    this.log('PLAY');
    const promise = this.originalPlayer.play();

    if (promise !== undefined) {
      promise.then(() => {
        // Auto-play started
        this.log('Auto-play started');
      }).catch((error) => {
        logger.error(this.playerId, 'Auto-play was prevented', error);
        // Auto-play was prevented
        // Show a UI element to let the user manually start playback
        if (error.name === 'NotAllowedError') {
          this.isPlaying = false;
          this.handleNotAllowedError();
        }
      });
    }
  }

  // the player doesn't know anything about video, it's duration, framerate, etc.
  // we need to wait for metadata before forcing player sync
  // more info about the readyState can be found here:
  // https://docs.videojs.com/docs/api/player.html#MethodsreadyState
  waitForMetadata(callback) {
    if (this.originalPlayer.readyState() < 1) {
      this.one(Events.LOADED_METADATA, callback);
    } else {
      callback();
    }
  }

  callWhenSeekable(callback) {
    this.waitForMetadata(() => {
      this.one(Events.TIME_UPDATE, () => {
        this.pause();
        callback();
      });
      this.play();
    });
  }

  // Chrome does not seek to future correctly if the player was never started.
  // When the player is on pause during initialization we should start and pause playback
  // in order to be able to seek
  setInitialCurrentTimeWhenPaused(value) {
    const maxSeekingRetries = 3;
    let seekingRetries = 0;
    if (value > 0) {
      const seekedHandler = () => {
        const seekedTime = this.getCurrentTime();
        // When seeking to far future Chrome looses time precision
        // so we should double-check the time and set it again if needed
        if (value.toFixed(3) !== seekedTime.toFixed(3) && seekingRetries < maxSeekingRetries) {
          seekingRetries += 1;
          this.setCurrentTime(value);
          return;
        }
        this.off(Events.SEEKED, seekedHandler);
        this.trigger(Events.CAUGHT_UP);
      };

      this.callWhenSeekable(() => {
        this.on(Events.SEEKED, seekedHandler);
        this.setCurrentTime(value);
      });
    } else {
      this.setCurrentTime(value);
      this.trigger(Events.CAUGHT_UP);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  handleNotAllowedError() {
    // do nothing, should be overriden
  }

  pause() {
    this.log('PAUSE');
    this.originalPlayer.pause();
  }

  on(eventName, callback) {
    this.originalPlayer.addEventListener(eventName, callback);
  }

  off(eventName, callback) {
    this.originalPlayer.removeEventListener(eventName, callback);
  }

  one(eventName, callback) {
    const listener = (event) => {
      callback(event);
      this.off(eventName, listener);
    };
    this.on(eventName, listener);
  }

  getCurrentTime() {
    return this.originalPlayer.currentTime;
  }

  setCurrentTime(value) {
    this.originalPlayer.currentTime = value;
  }

  playIfAllowed() {
    if (this.isPlaying) this.play();
  }

  get() {
    return this.originalPlayer;
  }

  log(...args) {
    if (this.enableLogging) logger.log(this.playerId, ...args);
  }

  // eslint-disable-next-line no-unused-vars,class-methods-use-this
  trigger(eventName) {
    this.log('TRIGGER', eventName);
    // do nothing, should be overriden
  }

  volumeUp() {
    this.log('VOLUME UP');
    // do nothing, should be overriden
  }

  volumeDown() {
    this.log('VOLUME DOWN');
    // do nothing, should be overriden
  }

  dispose() {
    this.log('DISPOSE');
    this.destroySyncStrategy();
    if (this.originalPlayer) {
      this.originalPlayer.dispose();
      this.originalPlayer = null;
    }
  }

  isDisposed() {
    return !this.originalPlayer || this.originalPlayer.isDisposed();
  }
}
