/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInProgress: false,
};

const pollingSlice = createSlice({
  name: 'polling',
  initialState,
  reducers: {
    startPolling(state) {
      state.isInProgress = true;
    },
    stopPolling(state) {
      state.isInProgress = false;
    },
  },
});

export const { startPolling, stopPolling } = pollingSlice.actions;

export default pollingSlice.reducer;
