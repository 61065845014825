import React from 'react';
import PropTypes from 'prop-types';
import apiConfig from '../../config/api-config';
import './styles.css';
import { logger } from '../../utils/logger';

function LoginForm({ setIsLoggedIn }) {
  const [login, setLogin] = React.useState('');
  const [password, setPassword] = React.useState('');

  const submit = async () => {
    try {
      const response = await fetch(`${apiConfig.baseUrl}/login`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ login, password }),
      });
      response.json().then(({ auth }) => {
        if (auth) {
          setIsLoggedIn(true);
        }
      });
    } catch (err) {
      logger.error(err.message);
    }
  };

  return (
    <div className="login-form-container">
      <div className="login-form">
        <label htmlFor="login" className="input">
          <span className="input__label">Login</span>
          <input
            id="login"
            className="input__field"
            type="text"
            placeholder=" "
            onChange={(e) => {
              setLogin(e.target.value);
            }}
          />
        </label>
        <label htmlFor="password" className="input">
          <span className="input__label">Password</span>
          <input
            id="password"
            className="input__field"
            type="text"
            placeholder=" "
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </label>
        <div className="button-container">
          <button type="button" onClick={submit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

LoginForm.propTypes = {
  setIsLoggedIn: PropTypes.func.isRequired,
};

export default LoginForm;
