export default class NoSyncStrategy {
  init(player) {
    this.destroy();
    this.player = player;
  }

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  handleTimeUpdate() {
    this.player.playIfAllowed();
    // do nothing!
  }

  destroy() {
    this.player = null;
  }
}
