export const secondsToHms = (secs) => {
  const hours = Math.floor(secs / 3600);
  const minutes = Math.floor(secs / 60) % 60;
  const seconds = Math.floor(secs % 60);
  return [hours, minutes, seconds]
    .map((v) => (v < 10 ? `0${v}` : v))
    .filter((v, i) => v !== '00' || i > 0)
    .join(':');
};

export const getFormattedEpisodeStartTime = (startTime) => {
  const date = new Date(startTime);
  const timePart = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    hour12: true,
    timeZoneName: 'short',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);

  const datePart = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  }).format(date);

  return `${timePart} | ${datePart}`;
};

export const getFormattedCountdownTime = (countdownTimeMS) => {
  let seconds = Math.floor(countdownTimeMS / 1000);
  let minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  seconds %= 60;
  minutes %= 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  // hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds,
  )}`;
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const convertMinutesToMilliseconds = (minutes) => minutes * 60 * 1000;
