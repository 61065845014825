import React from 'react';
import './EpisodePlayer.scss';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

import EpisodePlayerOnDemand from './components/EpisodePlayerOnDemand';
import EpisodePlayerLive from './components/EpisodePlayerLive';

const isPlayerConfigEqual = (oldValue, newValue) => {
  const isRoomIdTheSame = oldValue.main.roomId === newValue.main.roomId;
  const areSecondaryRoomIdsTheSame = oldValue.secondary.every(
    (camera, index) => camera.roomId === newValue.secondary[index].roomId,
  );
  return isRoomIdTheSame && areSecondaryRoomIdsTheSame;
};

function EpisodePlayer({ allowLivePlay }) {
  const isLiveMode = useSelector((state) => state.player.isLive);

  const { main: mainConfig, secondary: secondaryConfigs } = useSelector(
    (state) => state.player.camerasPosition,
    isPlayerConfigEqual,
  );

  return (
    <div className={`episode-player ${allowLivePlay ? '' : 'hidden'}`}>
      {isLiveMode ? (
        <EpisodePlayerLive
          mainConfig={mainConfig}
          secondaryConfigs={secondaryConfigs}
          allowLivePlay={allowLivePlay}
        />
      ) : (
        <EpisodePlayerOnDemand
          mainConfig={mainConfig}
          secondaryConfigs={secondaryConfigs}
        />
      )}
    </div>
  );
}

export default EpisodePlayer;

EpisodePlayer.propTypes = {
  allowLivePlay: bool,
};

EpisodePlayer.defaultProps = {
  allowLivePlay: false,
};
