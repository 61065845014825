import React, { useEffect, useState } from 'react';

import './TicketsList.scss';
import { SEASON_ID } from '../../../../constants';
import Ticket from '../../../../api/services/Ticket';
import Image from '../../../Image';

function TicketsList() {
  const [tickets, setTickets] = useState(null);

  useEffect(() => {
    Ticket.getTicketPrices(SEASON_ID).then((data) => {
      setTickets(data);
    });
  }, []);

  const getTicketPurchaseTitle = (name, ticketPrice) => (
    <>
      <span className="tickets-list-name">{name}</span>
      {' '}
      <span className="tickets-list-price">{`${ticketPrice}`}</span>
    </>
  );

  return (
    <div className="tickets-list-container">
      <div className="tickets-list-background">
        <div className="pin-box">
          <Image
            className="pin"
            src="/images/papers-and-pushpin/red-pushpin.png"
            alt="Pin"
            srcset={[{ imageUrl: '/images/papers-and-pushpin/red-pushpin.webp' }]}
          />
        </div>
        { tickets && (
          <div className="tickets-list-content">
            <h1 className="tickets-list-title">Pack 1</h1>
            <div
              className="tickets-list-box tickets-list-box-purchase"
              key="season-ticket-price"
            >
              {getTicketPurchaseTitle(
                'Purchase full bundle',
                'Fun is over',
              )}
            </div>
            <div className="tickets-list-subtitle">Or purchase episodes independently</div>
            {tickets.episodeTickets?.map((ticketData) => (
              <div
                className="tickets-list-box"
                key={`${ticketData.episodeId}-ticket-price`}
                data-testid={`${ticketData.episodeId}-ticket-price`}
              >
                {getTicketPurchaseTitle(ticketData.name, 'Fun is over')}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default TicketsList;
