import SyncStrategy from '../SyncStrategy/SyncStrategy';

export default class SyncWithPlayerStrategy extends SyncStrategy {
  constructor(playerToSyncWith) {
    super();
    this.playerToSyncWith = playerToSyncWith;
  }

  getTimeToSyncWith() {
    let timeToSyncWith;
    try {
      timeToSyncWith = this.playerToSyncWith.getCurrentTime();
    } catch (error) {
      timeToSyncWith = this.player.getCurrentTime();
    }
    return timeToSyncWith;
  }
}
