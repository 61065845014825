import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TicketValidationForm from '../../../TicketValidationForm';
import './ActivateTicketForm.scss';
import { DEFAULT_EPISODE_ID, SEASON_ID } from '../../../../constants';
import Image from '../../../Image';

function ActivateTicketForm({ closePopupAction }) {
  const navigate = useNavigate();
  const { episodeId } = useParams();

  const onActivateAction = (validationData) => {
    closePopupAction();

    if (!validationData.isSeasonal) {
      // episodeId without seasonId
      const validatedEpisodeId = validationData.episodeId?.slice(
        SEASON_ID.length,
      );

      navigate(`/episode/${validatedEpisodeId}`);
      return;
    }

    navigate(`/episode/${episodeId || DEFAULT_EPISODE_ID}`);
  };

  return (
    <div className="activate-ticket-form-container">
      <div className="activate-ticket-form-background">
        <div className="pin-box">
          <Image
            className="pin"
            src="/images/papers-and-pushpin/red-pushpin.png"
            alt="Pin"
            srcset={[{ imageUrl: '/images/papers-and-pushpin/red-pushpin.webp' }]}
          />
        </div>
        <div className="activate-ticket-form-content">
          <div className="activate-ticket-form-image-box">
            <div className="bottom-before" />
            <div className="bottom-after" />
            <Image
              src="/images/activate-ticket-popup/activate-ticket-logo.png"
              alt="Logo"
              className="activate-ticket-form-image"
              srcset={[{ imageUrl: '/images/activate-ticket-popup/activate-ticket-logo.webp' }]}
            />
          </div>
          <h1 className="activate-ticket-form-title">
            <div>Activate </div>
            <div>ticket</div>
          </h1>
          <p className="activate-ticket-form-label">Enter Unique Ticket Code</p>
          <TicketValidationForm onActivate={onActivateAction} />
        </div>
      </div>
    </div>
  );
}

export default ActivateTicketForm;

ActivateTicketForm.propTypes = {
  closePopupAction: PropTypes.func,
};

ActivateTicketForm.defaultProps = {
  closePopupAction: () => {},
};
