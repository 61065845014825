import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { stopPolling } from '../../../reducers/polling';
import { OPENED_TAB_KEY } from '../../../utils/tab-utils';

export function useTabDetection() {
  const [anotherTabDetected, setAnotherTabDetected] = useState();

  const dispatch = useDispatch();

  const detectAnotherTab = (event) => {
    if (event.key === OPENED_TAB_KEY) {
      setAnotherTabDetected(true);
      dispatch(stopPolling());
    }
  };

  useEffect(() => {
    window.addEventListener('storage', detectAnotherTab);

    return () => {
      window.removeEventListener('storage', detectAnotherTab);
    };
  }, []);

  return { anotherTabDetected };
}
