import React, { useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';

import HlsVideoPlayer from '../HlsVideoPlayer';
import controlBarConfig from '../../config/control-bar-config';
import FullscreenPopup from '../FullscreenPopup';

import './Trailer.scss';

const defaultOptions = {
  enableSourceset: true,
  loop: true,
  controlBar: controlBarConfig,
  loadingSpinner: false,
};

function Trailer({ src, poster, isFullScreen, onPopupClose }) {
  const [trailerOptions, setTrailerOptions] = useState(null);
  const [fullscreenTrailerOptions, setFullscreenTrailerOptions] = useState(null);

  useEffect(() => {
    if (!src) return;
    const options = {
      ...defaultOptions,
      sources: [{
        src,
        type: 'application/x-mpegURL',
      }],
    };
    setTrailerOptions({
      ...options,
      autoplay: true,
      muted: true,
      controls: false,
      bigPlayButton: false,
      playsinline: true,
    });

    setFullscreenTrailerOptions({
      ...options,
      muted: false,
      controls: true,
      bigPlayButton: true,
      poster,
    });
  }, [src, isFullScreen, poster]);

  return trailerOptions && (
    <>
      <div className="trailer-placeholder" data-testid="trailer">
        <div className="trailer">
          <HlsVideoPlayer options={trailerOptions} />
        </div>
      </div>
      {isFullScreen && (
        <FullscreenPopup onClose={onPopupClose}>
          <HlsVideoPlayer options={fullscreenTrailerOptions} />
        </FullscreenPopup>
      )}
    </>
  );
}

export default Trailer;

Trailer.propTypes = {
  poster: string,
  src: string.isRequired,
  isFullScreen: bool,
  onPopupClose: func.isRequired,
};

Trailer.defaultProps = {
  poster: null,
  isFullScreen: false,
};
