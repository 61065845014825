import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setStartTime } from '../reducers/episode';
import Episode from '../api/services/Episode';
import { logger } from '../utils/logger';

export default function useEpisodeData(episodeId) {
  const [episodeInfo, setEpisodeInfo] = useState(null);
  const [episodeStartTime, setEpisodeStartTime] = useState('');
  const [episodeEndTime, setEpisodeEndTime] = useState('');
  const dispatch = useDispatch();

  const setTime = (startTime, endTime) => {
    if (startTime) {
      setEpisodeStartTime(startTime);
      dispatch(setStartTime(startTime));
    }
    if (endTime) {
      setEpisodeEndTime(endTime);
    }
  };

  useEffect(() => {
    Episode.getEpisodePublicData(episodeId)
      .then((info) => {
        setEpisodeInfo({ ...info });

        const { startTime, endTime } = info || {};
        setTime(startTime, endTime);
      })
      .catch((err) => {
        logger.error(err.message);
      });
  }, [episodeId]);

  return { episodeInfo, episodeStartTime, episodeEndTime };
}
