import { arrayOf, bool, number, shape, string } from 'prop-types';
import cameraObject from './cameraObject';

export default shape({
  height: number,
  enableSourceset: bool,
  userActions: shape({
    doubleClick: bool,
  }),
  controlBar: shape({
    fullscreenToggle: bool,
    seekToLive: bool,
  }),
  playsinline: bool,
  cameraId: string,
  sources: arrayOf(cameraObject),
});
