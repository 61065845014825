import apiConfig from '../../config/api-config';
import { EPISODES_KEY, SEASONS_KEY } from '../../constants/localStorageKeys';
import { EPISODE_ACTIVATION_DATA_UPDATE } from '../../constants/eventNames';

async function validate(activationCode) {
  const queryParams = new URLSearchParams({
    activationCode: encodeURIComponent(activationCode),
  });
  const url = `${apiConfig.baseUrl}/ticket/validate?${queryParams}`;
  const response = await fetch(url, { credentials: 'include' });
  return response.json();
}

function updateValidatedEpisodesData(validationInfo) {
  const validatedItems = localStorage.getItem(
    validationInfo.isSeasonal ? SEASONS_KEY : EPISODES_KEY,
  );
  const data = validatedItems ? JSON.parse(validatedItems) : {};

  data[
    validationInfo.isSeasonal
      ? validationInfo.seasonId
      : validationInfo.episodeId
  ] = validationInfo.sessionExpirationDate;

  localStorage.setItem(
    validationInfo.isSeasonal ? SEASONS_KEY : EPISODES_KEY,
    JSON.stringify(data),
  );

  window.dispatchEvent(new Event(EPISODE_ACTIVATION_DATA_UPDATE));
}

function invalidateEpisodeData(episodeId, seasonId) {
  const validatedSeasons = JSON.parse(localStorage.getItem(SEASONS_KEY) || '{}');
  const validatedEpisodes = JSON.parse(localStorage.getItem(EPISODES_KEY) || '{}');

  if (validatedEpisodes[episodeId]) {
    delete validatedEpisodes[episodeId];
    localStorage.setItem(EPISODES_KEY, JSON.stringify(validatedEpisodes));
    window.dispatchEvent(new Event(EPISODE_ACTIVATION_DATA_UPDATE));
  } else if (validatedSeasons[seasonId]) {
    delete validatedSeasons[seasonId];
    localStorage.setItem(SEASONS_KEY, JSON.stringify(validatedSeasons));
    window.dispatchEvent(new Event(EPISODE_ACTIVATION_DATA_UPDATE));
  }
}

async function poll(episodeId, seasonId) {
  const queryParams = new URLSearchParams({
    episodeId: encodeURIComponent(episodeId),
    seasonId: encodeURIComponent(seasonId),
  });

  const url = `${apiConfig.baseUrl}/ticket/poll?${queryParams}`;
  const response = await fetch(url, { credentials: 'include' });

  if (response.ok) {
    return response.json();
  }
  return {
    body: await response.json(),
    status: response.status,
  };
}

async function getTicketPrices(seasonId) {
  const queryParams = new URLSearchParams({
    seasonId: encodeURIComponent(seasonId),
  });
  const response = await fetch(`${apiConfig.baseUrl}/ticket/prices?${queryParams}`, {
    credentials: 'include',
  });
  return response.json();
}

export default {
  validate,
  updateValidatedEpisodesData,
  invalidateEpisodeData,
  poll,
  getTicketPrices,
};
