import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import videojs from 'video.js';
import { func, string } from 'prop-types';
import CustomPlayerVideoJS from '../../../../wrappers/CustomPlayerVideoJS';
import SyncWithElapsedTimeStrategy from '../../../../strategies/SyncWithElapsedTimeStrategy';
import Events from '../../../../constants/events';
import cameraOptions from '../../../../types/cameraOptions';
import { disableMainCameraSwitch, enableMainCameraSwitch, mute, unmute } from '../../../../../../reducers/player';
import Spinner from '../../../../../Spinner/Spinner';
import { replaceSpinnerForPlayer } from '../../../../../../utils/vjs-utils';

export function MainCameraLive(props) {
  const dispatch = useDispatch();

  const placeholderRef = useRef(null);
  const playerRef = useRef(null);
  const oldPlayerRef = useRef(null);
  const customPlayerRef = useRef(null);
  const oldCustomPlayerRef = useRef(null);
  const { options, onReady, roomId } = props;

  const [isCameraSwitchInProgress, setIsCameraSwitchInProgress] = useState(false);

  const isMuted = useSelector((state) => state.player.isMuted);

  const muteIfRequired = (player) => {
    if (player) {
      if (isMuted) player.mute();
      else player.unmute();
    }
  };

  const onPlayerReady = (player) => {
    if (player) {
      const customPlayer = new CustomPlayerVideoJS(player, options.cameraId, true);

      if (oldPlayerRef.current && oldCustomPlayerRef.current.isPaused()) {
        initNewPlayerInsteadOfCurrent(customPlayer);
      } else if (oldPlayerRef.current) {
        initNewPlayerAndSyncWithCurrent(customPlayer);
      } else {
        customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());
        customPlayer.volumeUp();
      }

      subscribeOnUnmuteButton(customPlayer);
      muteIfRequired(customPlayer);

      onReady(customPlayer);

      customPlayer.hideControls();

      customPlayerRef.current = customPlayer;
    }
  };

  useEffect(() => {
    if (customPlayerRef?.current) {
      muteIfRequired(customPlayerRef.current);
    }
  }, [isMuted, customPlayerRef]);

  useEffect(() => {
    if (customPlayerRef.current) {
      customPlayerRef.current.volumeDown();
    }
  }, [roomId]);

  useEffect(() => {
    if (!playerRef.current) {
      createPlayer();
    } else if (playerRef.current && !customPlayerRef.current) {
      // the player started initializing but is not ready yet
      playerRef.current.dispose();
      createPlayer();
    } else {
      destroyOldPlayer();
      assignOldPlayer();
      createPlayer();
    }
  }, [options]);

  function assignOldPlayer() {
    oldPlayerRef.current = playerRef.current;
    oldCustomPlayerRef.current = customPlayerRef.current;
    customPlayerRef.current = null;
    oldCustomPlayerRef.current.playerId += '_old';

    document.getElementById(oldPlayerRef.current.id()).style.display = 'none';
  }

  function destroyOldPlayer() {
    const oldPlayer = oldPlayerRef.current;
    const oldCustomPlayer = oldCustomPlayerRef.current;

    if (oldPlayer && !oldPlayer.isDisposed()) {
      oldPlayer.dispose();
      oldPlayerRef.current = null;
    }

    if (oldCustomPlayer) {
      oldCustomPlayer.dispose();
      oldCustomPlayerRef.current = null;
    }
  }

  function createPlayer() {
    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(
      document.createElement('video-js'),
    );

    // eslint-disable-next-line no-multi-assign
    const player = (playerRef.current = videojs(videoElement, options, () => {
      replaceSpinnerForPlayer(player);
      onPlayerReady(player);
    }));
  }

  function initNewPlayerInsteadOfCurrent(customPlayer) {
    setIsCameraSwitchInProgress(true);

    const seekedHandler = () => {
      customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());

      setIsCameraSwitchInProgress(false);

      customPlayer.off(Events.SEEKED, seekedHandler);
    };
    customPlayer.on(Events.SEEKED, seekedHandler);
    customPlayer.setCurrentTime(oldCustomPlayerRef.current.getCurrentTime());
    destroyOldPlayer();
    customPlayer.volumeUp();
  }

  function initNewPlayerAndSyncWithCurrent(customPlayer) {
    setIsCameraSwitchInProgress(true);

    const caughtUpHandler = () => {
      customPlayer.volumeUp();

      setIsCameraSwitchInProgress(false);

      destroyOldPlayer();
      customPlayer.off(Events.CAUGHT_UP, caughtUpHandler);
    };
    customPlayer.on(Events.CAUGHT_UP, caughtUpHandler);

    customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());
  }

  function subscribeOnUnmuteButton(customPlayer) {
    customPlayer.on(Events.UNMUTE_BUTTON_SHOW, () => {
      dispatch(mute());
    });
    customPlayer.on(Events.UNMUTE_BUTTON_HIDE, () => {
      dispatch(unmute());
    });
  }

  useEffect(() => {
    if (isCameraSwitchInProgress) {
      dispatch(disableMainCameraSwitch());
    } else {
      dispatch(enableMainCameraSwitch());
    }
  }, [isCameraSwitchInProgress]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => () => {
    const player = playerRef.current;
    const customPlayer = customPlayerRef.current;

    if (player && !player.isDisposed()) {
      player.pause();
      player.dispose();
      playerRef.current = null;
    }

    if (customPlayer) {
      customPlayer.dispose();
      customPlayerRef.current = null;
    }

    if (oldPlayerRef.current && !player.isDisposed()) {
      oldPlayerRef.current.dispose();
      oldPlayerRef.current = null;
    }

    if (oldCustomPlayerRef.current) {
      oldCustomPlayerRef.current.dispose();
      oldCustomPlayerRef.current = null;
    }
  }, []);

  return (
    <div className="camera">
      <div ref={placeholderRef} />
      {isCameraSwitchInProgress && (
      <div className="sync-overlay">
        <Spinner />
      </div>
      )}
    </div>
  );
}

export default MainCameraLive;

MainCameraLive.propTypes = {
  options: cameraOptions.isRequired,
  onReady: func.isRequired,
  roomId: string.isRequired,
};
