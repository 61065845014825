import { func } from 'prop-types';
import React from 'react';
import FullscreenPopup from '../../../FullscreenPopup';
import ActivateTicketForm from '../ActivateTicketForm';
import TicketsList from '../TicketsList';
import './ActivateTicketPopup.scss';

function ActivateTicketPopup({ onClose }) {
  return (
    <FullscreenPopup onClose={onClose} className="activate-ticket-popup">
      <div className="activate-ticket-popup-background" />
      <div className="activate-ticket-popup-container">
        <TicketsList />
        <ActivateTicketForm closePopupAction={onClose} />
      </div>
    </FullscreenPopup>
  );
}

export default ActivateTicketPopup;

ActivateTicketPopup.propTypes = {
  onClose: func.isRequired,
};
