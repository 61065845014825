import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import '../../EpisodePlayer.scss';
import { setVideoEnded, setPlayedTime, play } from '../../../../reducers/player';
import { useEpisodeLiveTimer } from '../../hooks/useEpisodeLiveTimer';
import SecondaryVideos from '../SecondaryVideos';
import PrimaryVideo from '../PrimaryVideo';

function EpisodePlayerLive({ mainConfig, secondaryConfigs, allowLivePlay }) {
  const [mainPlayerReady, setMainPlayerReady] = useState(false);
  const [secondaryPlayersReady, setSecondaryPlayersReady] = useState(false);
  const [shouldPlay, setShouldPlay] = useState(false);

  const mainPlayerRef = useRef(null);
  const secondaryPlayersRef = useRef([null, null, null]);

  const isPlaying = useSelector((state) => state.player.isPlaying);
  const dispatch = useDispatch();

  const { isTimerReady } = useEpisodeLiveTimer();

  useEffect(() => {
    setShouldPlay(!!isPlaying && !!allowLivePlay);
  }, [isPlaying, allowLivePlay]);

  const onSecondaryPlayerReady = useCallback((player, index) => {
    secondaryPlayersRef.current[index] = player;
    player.setIsPlaying(shouldPlay);

    if (!secondaryPlayersRef.current.some((secondaryPlayer) => secondaryPlayer == null)) {
      setSecondaryPlayersReady(true);
    }
  }, [setSecondaryPlayersReady, shouldPlay]);

  const onMainPlayerReady = useCallback((player) => {
    mainPlayerRef.current = player;

    const mainPlayer = mainPlayerRef.current;

    if (mainPlayer) {
      setMainPlayerReady(true);
      mainPlayer.on('timeupdate', () => {
        dispatch(setPlayedTime(mainPlayer.getCurrentTime()));
      });

      mainPlayer.on('ended', () => {
        onVideoEnded();
      });

      if (shouldPlay) {
        mainPlayer.setIsPlaying(true);
      }
    }
  }, [shouldPlay]);

  useEffect(() => {
    if (isTimerReady && mainPlayerReady && secondaryPlayersReady) {
      dispatch(play());
    }
  }, [isTimerReady, mainPlayerReady, secondaryPlayersReady]);

  function onVideoEnded() {
    dispatch(setVideoEnded());
  }

  useEffect(() => {
    const mainPlayer = mainPlayerRef.current;
    if (!mainPlayerReady || !secondaryPlayersReady) return;

    mainPlayer.setIsPlaying(shouldPlay);
    secondaryPlayersRef.current
      .filter((player) => player !== null)
      .forEach((player) => player.setIsPlaying(shouldPlay));
  }, [shouldPlay, mainPlayerReady, secondaryPlayersReady]);

  return (
    <div className={`videos episode-players ${allowLivePlay ? '' : 'hidden'}`} data-testid="videos">
      <div className="episode-players-wrapper">
        {mainConfig?.roomId ? (
          <PrimaryVideo
            roomId={mainConfig.roomId}
            onReady={onMainPlayerReady}
          />
        ) : null}
        <div className="secondary">
          <SecondaryVideos
            configs={secondaryConfigs}
            onReady={onSecondaryPlayerReady}
          />
        </div>
        {/* {mainPlayer && secondaryPlayersReady && ( */}
        {/*   <LiveModeControls */}
        {/*     playedSeconds={playedSeconds} */}
        {/*     startTime={playerStartTime} */}
        {/*   /> */}
        {/* )} */}
      </div>
    </div>
  );
}

export default EpisodePlayerLive;

EpisodePlayerLive.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mainConfig: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  secondaryConfigs: PropTypes.array,
  allowLivePlay: bool,
};

EpisodePlayerLive.defaultProps = {
  mainConfig: null,
  secondaryConfigs: [],
  allowLivePlay: true,
};
