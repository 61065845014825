import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Image from '../../Image';
import usePremiereSettings from '../../../hooks/usePremiereSettings';
import { episodeModes } from '../../../constants/episodeModes';
import './EpisodeCoverImage.scss';

function EpisodeCoverImage({ episodeInfo, isCurrentEpisode }) {
  const {
    startTime,
    endTime,
    showCountdownBeforeHr,
    countdownVideoDurationMin,
    coverImage,
    episodeNumber,
  } = episodeInfo;

  const { mode: episodeMode } = usePremiereSettings({
    episodeStartTime: startTime,
    episodeEndTime: endTime,
    showCountdownBeforeHr,
    countdownVideoDurationMin,
  });

  const isLiveMode = episodeMode === episodeModes.LIVE
    || episodeMode === episodeModes.WAITING_ROOM;

  return (
    <Link
      to={`/episode/e${episodeInfo.episodeNumber}`}
      data-testid={`episode-cover-${episodeInfo.episodeNumber}`}
    >
      <div
        className={`episode-cover-image ${
          isCurrentEpisode ? 'current-episode-cover-image' : ''
        }`}
      >
        <Image
          src={coverImage?.src}
          srcset={coverImage?.srcset}
          alt={`cover image for episode number ${episodeNumber}`}
        />
        {isLiveMode && <div className="live-label">LIVE</div>}
      </div>
    </Link>
  );
}

export default EpisodeCoverImage;

EpisodeCoverImage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  episodeInfo: PropTypes.object,
  isCurrentEpisode: PropTypes.bool,
};

EpisodeCoverImage.defaultProps = {
  episodeInfo: {},
  isCurrentEpisode: false,
};
