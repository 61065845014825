/**
 * @param {*} sources can be only an array of `{imageUrl:"link to image", width: 250}`,
 *  where `width` is _number of pixels_.
 * @returns string formatted as srcset for <img/>
 */
export function formatSrcSet(sources) {
  if (Array.isArray(sources)) {
    return sources
      .map(({ imageUrl, width }) => `${imageUrl} ${width ? `${width}w` : '1x'}`)
      .join(', ');
  }
  return undefined;
}
