/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startTime: '',
};

const episodeSlice = createSlice({
  name: 'episode',
  initialState,
  reducers: {
    setStartTime(state, action) {
      state.startTime = action.payload;
    },
  },
});

export const { setStartTime } = episodeSlice.actions;

export default episodeSlice.reducer;
