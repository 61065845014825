import apiConfig from '../../config/api-config';
import { SEASON_ID } from '../../constants';

function getSeasonData() {
  const queryParams = new URLSearchParams({
    seasonId: encodeURIComponent(SEASON_ID),
  });
  return fetch(`${apiConfig.baseUrl}/season/info?${queryParams}`, {
    credentials: 'include',
  }).then((data) => data.json());
}

export default { getSeasonData };
