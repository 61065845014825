import React from 'react';
import { func, string } from 'prop-types';

import ActivateTicketButton from '../../../ActivateTicket/ActivateTicketButton';
import HowToPlayButton from '../../../HowToPlay';

import './AlwaysDisplayedLinks.scss';

function AlwaysDisplayedLinks({ pathMainPart, toggleSidebar }) {
  return (
    <div className="popup-buttons-container">
      {pathMainPart === 'episode' ? (
        <HowToPlayButton className="btn btn-outline-secondary how-to-play-btn" />
      ) : (
        <>
          <ActivateTicketButton className="btn btn-link" />
          <HowToPlayButton className="btn btn-link how-to-play-btn" />
          <button type="button" aria-label="burger-menu" className="btn btn-burger" onClick={toggleSidebar} />
        </>
      )}
    </div>
  );
}
AlwaysDisplayedLinks.propTypes = {
  pathMainPart: string,
  toggleSidebar: func,
};

AlwaysDisplayedLinks.defaultProps = {
  pathMainPart: '',
  toggleSidebar: () => {},
};

export default AlwaysDisplayedLinks;
