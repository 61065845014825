import React, { useEffect } from 'react';
import './FullscreenPopup.scss';
import { arrayOf, func, oneOfType, node } from 'prop-types';
import ReactDOM from 'react-dom';

export function FullscreenPopup(props) {
  const { onClose, className, children } = props;

  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);
  return ReactDOM.createPortal(
    <div className="fullscreen-popup">
      <div className={`fullscreen-popup-content ${className || ''}`}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className="btn btn-close" type="button" data-testid="fullscreen-popup-close-button" onClick={onClose} />
        <div className="fullscreen-popup-body">
          {children}
        </div>
      </div>
    </div>,
    document.body,
  );
}

export default FullscreenPopup;

FullscreenPopup.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node,
  ]),
  onClose: func,
};

FullscreenPopup.defaultProps = {
  children: [],
  onClose: () => {},
};
