import { useRef, useEffect, useState } from 'react';
import { getCurrentTime } from '../api/services/Timer';
import { episodeModes } from '../constants/episodeModes';
import { logger } from '../utils/logger';

const HOUR = 3600000;
const MIN = 60000;
const MAX_TIMEOUT_VALUE = 24 * HOUR; // 1 day
const PREPARE_VIDEO_PLAYER_BEFORE_LIVE_MS = 10_000;

export default function usePremiereSettings(props) {
  const {
    episodeStartTime,
    episodeEndTime,
    showCountdownBeforeHr,
    countdownVideoDurationMin,
    forceGetCurrentTimeRequest = false,
  } = props;

  const [currentDateTime, setCurrentDateTime] = useState(null);
  const [mode, setMode] = useState('');
  const [shouldPreparePlayers, setShouldPreparePlayers] = useState(false);
  const timeoutRef = useRef(null);
  const preparePlayersTimeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    getCurrentTime(forceGetCurrentTimeRequest)
      .then((data) => {
        setCurrentDateTime(data);
      })
      .catch((err) => {
        logger.error(err.message);
      });

    return resetTimeout;
  }, []);

  useEffect(() => {
    if (!episodeStartTime || !episodeEndTime || !currentDateTime) return;

    getCurrentTime().then((data) => {
      const currentTimeMS = data.getTime();
      const episodeStartTimeMS = new Date(episodeStartTime).getTime();
      const episodeEndTimeMS = new Date(episodeEndTime).getTime();

      if (episodeEndTimeMS < currentTimeMS) {
        setMode(episodeModes.ON_DEMAND);
      } else if (episodeStartTimeMS > currentTimeMS) {
        const timeLeftMS = Math.abs(data - new Date(episodeStartTime));
        const countdownVideoDurationMS = countdownVideoDurationMin * 60000;
        if (timeLeftMS < showCountdownBeforeHr * HOUR) {
          if (timeLeftMS > countdownVideoDurationMS) {
            setMode(episodeModes.COUNTDOWN);
          } else {
            setMode(episodeModes.WAITING_ROOM);
          }
        } else {
          setMode(episodeModes.NOT_STARTED);
        }
      } else {
        setMode(episodeModes.LIVE);
      }
    });
  }, [
    episodeStartTime,
    episodeEndTime,
    currentDateTime,
    showCountdownBeforeHr,
    countdownVideoDurationMin,
  ]);

  useEffect(() => {
    if (!mode) return;
    resetTimeout();

    getCurrentTime().then((now) => {
      const timeLeftMS = Math.abs(now.getTime() - new Date(episodeStartTime).getTime());
      let nextMode;
      let delay;

      const timeLiftUntilPlayersPreparation = timeLeftMS - PREPARE_VIDEO_PLAYER_BEFORE_LIVE_MS;
      const preparePlayersDelay = timeLiftUntilPlayersPreparation > 0
        ? timeLiftUntilPlayersPreparation
        : 0;

      switch (mode) {
        case episodeModes.NOT_STARTED:
          nextMode = episodeModes.COUNTDOWN;
          delay = timeLeftMS - showCountdownBeforeHr * HOUR;
          break;
        case episodeModes.COUNTDOWN:
          nextMode = episodeModes.WAITING_ROOM;
          delay = timeLeftMS - countdownVideoDurationMin * MIN;
          break;
        case episodeModes.WAITING_ROOM:
          nextMode = episodeModes.LIVE;
          delay = timeLeftMS;

          preparePlayersTimeoutRef.current = setTimeout(() => {
            setShouldPreparePlayers(true);
          }, preparePlayersDelay);
          break;
        case episodeModes.LIVE:
          nextMode = episodeModes.ON_DEMAND;
          delay = Math.abs(now - new Date(episodeEndTime));
          break;
        default:
          nextMode = mode;
          delay = 0;
      }

      if (delay <= MAX_TIMEOUT_VALUE) {
        timeoutRef.current = setTimeout(() => {
          setMode(nextMode);
        }, delay);
      }
    }).catch(logger.error);
  }, [mode]);

  return { mode, currentDateTime, shouldPreparePlayers };
}
