import React, { memo, useCallback, useEffect, useState } from 'react';
import { bool, func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Camera from '../Camera';
import {
  disableMainCameraSwitch,
  setActiveCameraId,
  switchToMainCamera,
  selectActiveCameraId,
  selectCameras,
} from '../../../../reducers/player';
import { CameraIcons, camerasOrderToDisplay } from '../../constants/cameras';

export function Room(props) {
  const { roomId, onReady, isMain } = props;

  const [isCameraSwitchInProgress, setCameraSwitchInProgress] = useState(null);

  const activeCameraId = useSelector((state) => selectActiveCameraId(state, roomId));
  const cameras = useSelector((state) => selectCameras(state, roomId, isMain));
  const isMainCameraSwitchEnabled = useSelector((state) => state.player.isMainCameraSwitchEnabled);

  const dispatch = useDispatch();

  const onCameraReady = useCallback((player) => {
    setCameraSwitchInProgress(false);
    onReady(player);
  }, [onReady]);

  useEffect(() => {
    setCameraSwitchInProgress(true);
  }, [activeCameraId]);

  const memoizedCamera = (
    <Camera
      onReady={onCameraReady}
      activeCameraId={activeCameraId}
      isMain={isMain}
      roomId={roomId}
      cameras={cameras}
    />
  );

  const switchCamera = (cameraId) => {
    dispatch(setActiveCameraId({
      roomId,
      activeCameraId: cameraId,
    }));

    if (isMain) {
      dispatch(disableMainCameraSwitch());
    }
  };

  const setMainCamera = () => {
    dispatch(switchToMainCamera({ roomId }));
    dispatch(disableMainCameraSwitch());
  };

  return (
    <div className="video-room">
      {activeCameraId && memoizedCamera}
      {isMain ? (
        <div className="room-buttons">
          {camerasOrderToDisplay.map((cameraType) => {
            const camera = cameras.find((cam) => cam.cameraType === cameraType);

            return (
              <button
                className={`btn btn-icon ${
                  camera.cameraId === activeCameraId ? 'btn-live' : ''
                }`}
                type="button"
                key={camera.cameraId}
                onClick={() => switchCamera(camera.cameraId)}
                disabled={
                  (!!isMain && !!isCameraSwitchInProgress)
                  || camera.cameraId === activeCameraId
                  || !isMainCameraSwitchEnabled
                }
              >
                <img src={CameraIcons[camera.cameraType]} alt="icon" />
              </button>
            );
          })}
        </div>
      ) : (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          type="button"
          onClick={setMainCamera}
          disabled={!isMainCameraSwitchEnabled}
        />
      )}
    </div>
  );
}

export default memo(Room);

Room.propTypes = {
  onReady: func.isRequired,
  isMain: bool,
  roomId: string.isRequired,
};

Room.defaultProps = {
  isMain: false,
};
