import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Episode from '../../../api/services/Episode';
import CardsSlider from '../../CardsSlider';
import EpisodeCoverImage from '../EpisodeCoverImage';

const sliderOptions = {
  perPage: 1.5,
  gap: 12,
  perMove: 1,
  padding: 20,
  breakpoints: {
    576: {
      perPage: 2,
    },
    992: {
      perPage: 3,
    },
    1440: {
      perPage: 4,
    },
  },

};

function EpisodeCoverImagesCarousel() {
  const { episodeId: currentEpisodeId } = useParams();

  const [episodesInfo, setEpisodesInfo] = useState([]);

  useEffect(() => {
    Episode.getEpisodesPublicData().then((info) => {
      setEpisodesInfo(info);
    });
  }, []);

  const coverCards = episodesInfo?.map((episodeInfo) => (
    <EpisodeCoverImage
      episodeInfo={episodeInfo}
      isCurrentEpisode={currentEpisodeId === `e${episodeInfo.episodeNumber}`}
      key={`episode-cover-${episodeInfo.episodeNumber}`}
    />
  ));

  if (!episodesInfo.length) {
    return null;
  }
  return <CardsSlider cards={coverCards} options={sliderOptions} />;
}

export default EpisodeCoverImagesCarousel;
