export const CameraTypes = {
  CLOSE: 'close',
  WIDE: 'wide',
  GO_PRO: 'goPro',
};

export const CameraIcons = {
  [CameraTypes.CLOSE]: '/images/icons/camera-icon.svg',
  [CameraTypes.WIDE]: '/images/icons/monitor-icon.svg',
  [CameraTypes.GO_PRO]: '/images/icons/photo-camera-icon.svg',
};

export const camerasOrderToDisplay = [
  CameraTypes.CLOSE,
  CameraTypes.WIDE,
  CameraTypes.GO_PRO,
];
