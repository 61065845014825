import React, { useMemo } from 'react';
import './Spinner.scss';

const SPINNER_ITEMS_COUNT = 5;

export function Spinner() {
  const spinnerItems = useMemo(() => [...Array(SPINNER_ITEMS_COUNT)].map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <div className="spinner-item" key={`spinner-item-${i}`} />
  )));

  return (
    <div className="spinner" data-testid="spinner">
      {spinnerItems}
    </div>
  );
}

export default Spinner;
