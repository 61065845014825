import React from 'react';
import { number, string } from 'prop-types';
import usePremiereSettings from '../../hooks/usePremiereSettings';
import PremiereCountdown from '../PremiereCountdown';
import { getFormattedEpisodeStartTime } from '../../utils/utils';
import { episodeModes } from '../../constants/episodeModes';

function EpisodePremiereInfo(props) {
  const {
    episodeStartTime,
    episodeEndTime,
    showCountdownBeforeHr,
    countdownVideoDurationMin,
  } = props;

  const { mode, currentDateTime } = usePremiereSettings({
    episodeStartTime,
    episodeEndTime,
    showCountdownBeforeHr,
    countdownVideoDurationMin,
  });

  return mode ? (
    <div data-testid="premiere-info">
      {(mode === episodeModes.LIVE || mode === episodeModes.WAITING_ROOM) && <div>LIVE</div>}
      {mode === episodeModes.COUNTDOWN && (
        <p>
          Live premiere
          <br />
          <PremiereCountdown
            timeToStart={new Date(episodeStartTime) - currentDateTime}
            endBefore={countdownVideoDurationMin * 60000}
          />
        </p>
      )}
      {mode === episodeModes.NOT_STARTED && (
        <p>
          Live premiere
          <br />
          {getFormattedEpisodeStartTime(episodeStartTime)}
        </p>
      )}
      {mode === episodeModes.ON_DEMAND && (
        <div>Available on demand</div>
      )}
    </div>
  ) : null;
}

export default EpisodePremiereInfo;

EpisodePremiereInfo.propTypes = {
  episodeStartTime: string,
  episodeEndTime: string,
  showCountdownBeforeHr: number,
  countdownVideoDurationMin: number,
};

EpisodePremiereInfo.defaultProps = {
  episodeStartTime: '',
  episodeEndTime: '',
  showCountdownBeforeHr: 0,
  countdownVideoDurationMin: 0,
};
