import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomPlayerVideoJS from '../../../../wrappers/CustomPlayerVideoJS';
import SyncWithElapsedTimeStrategy from '../../../../strategies/SyncWithElapsedTimeStrategy';
import Events from '../../../../constants/events';
import MainCameraView from '../MainCameraView';
import NoSyncStrategy from '../../../../strategies/NoSyncStrategy';
import cameraOptions from '../../../../types/cameraOptions';
import cameraObject from '../../../../types/cameraObject';
import { disableMainCameraSwitch, enableMainCameraSwitch } from '../../../../../../reducers/player';

export function MainCameraIOSOnDemand(props) {
  const dispatch = useDispatch();

  const customPlayerRef = useRef(null);
  const playersRef = useRef([null, null, null]);
  const customPlayersRef = useRef([null, null, null]);
  const { options, onReady, cameras, activeCameraId } = props;

  const [isReady, setIsReady] = useState(false);

  const isLive = useSelector((state) => state.player.isLive);

  const isPaused = useRef(true);
  const currentTime = useRef(0);

  const onPlayerUnmount = (paused, playerCurrentTime) => {
    if (currentTime.current > 0) return;
    isPaused.current = paused;
    currentTime.current = playerCurrentTime;

    customPlayerRef.current = null;
    customPlayersRef.current = [null, null, null];
    setIsReady(false);
  };

  function initPlayer(customPlayer) {
    if (currentTime.current > 0) {
      customPlayer.one(Events.SEEKED, () => {
        setSyncStrategy(customPlayer);
        onReady(customPlayer);
        currentTime.current = 0;

        dispatch(enableMainCameraSwitch());
      });
      customPlayer.setIsPlaying(!isPaused.current, true);
      customPlayer.setCurrentTime(currentTime.current);
      customPlayerRef.current = customPlayer;
      customPlayer.volumeUp();
    } else {
      customPlayer.setIsPlaying(!isPaused.current, true);
      customPlayerRef.current = customPlayer;
      customPlayer.volumeUp();
      setSyncStrategy(customPlayer);
      onReady(customPlayer);
      dispatch(enableMainCameraSwitch());
    }

    detectSeekingEvent(customPlayer);
  }

  function setSyncStrategy(customPlayer) {
    const strategy = isLive ? new SyncWithElapsedTimeStrategy() : new NoSyncStrategy();
    customPlayer.setSyncStrategy(strategy);
  }

  const onPlayerReady = (player, index) => {
    if (player) {
      playersRef.current[index] = player;
      const customPlayer = new CustomPlayerVideoJS(player, cameras[index].cameraId, true);
      customPlayer.volumeDown();

      customPlayersRef.current[index] = customPlayer;

      if (!customPlayersRef.current.some((playerObj) => playerObj == null)) {
        setIsReady(true);
        initPlayer(customPlayersRef.current[getCameraIndexById(activeCameraId)]);
      }
    }
  };

  useEffect(() => {
    if (!isReady) return;

    const activeCameraIndex = getCameraIndexById(activeCameraId);
    const newCustomPlayer = customPlayersRef.current[activeCameraIndex];

    if (customPlayerRef.current) {
      initNewPlayerInsteadOfCurrent(newCustomPlayer);
    }
  }, [activeCameraId]);

  function getCameraIndexById(cameraId) {
    const index = cameras
      .findIndex((camera) => camera.cameraId === cameraId);
    return index > 0 ? index : 0;
  }

  function destroyOldPlayer() {
    customPlayerRef.current.volumeDown();
    customPlayerRef.current.setIsPlaying(false);
  }

  function initNewPlayerInsteadOfCurrent(customPlayer) {
    customPlayer.one(Events.SEEKED, () => {
      setSyncStrategy(customPlayer);
      onReady(customPlayer);
      dispatch(enableMainCameraSwitch());
      detectSeekingEvent(customPlayer);
    });

    const isPlayerPaused = customPlayerRef.current.isPaused();
    destroyOldPlayer();
    customPlayer.setIsPlaying(!isPlayerPaused, true);
    customPlayer.setCurrentTime(customPlayerRef.current.getCurrentTime());
    customPlayerRef.current = customPlayer;
    customPlayer.volumeUp();
  }

  function detectSeekingEvent(customPlayer) {
    customPlayer.on(Events.SEEKING, () => {
      dispatch(disableMainCameraSwitch());
    });

    customPlayer.on(Events.SEEKED, () => {
      dispatch(enableMainCameraSwitch());
    });
  }

  return (
    <div>
      {cameras.map((camera, index) => (
        <MainCameraView
          key={camera.cameraId}
          isVisible={camera.cameraId === activeCameraId}
          options={{ ...options, sources: [camera.source] }}
          onReady={(player) => onPlayerReady(player, index)}
          onUnmount={onPlayerUnmount}
        />
      ))}
    </div>
  );
}

export default MainCameraIOSOnDemand;

MainCameraIOSOnDemand.propTypes = {
  options: cameraOptions.isRequired,
  onReady: func.isRequired,
  cameras: arrayOf(cameraObject).isRequired,
  activeCameraId: string.isRequired,
};
