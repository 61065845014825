import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import { func, bool } from 'prop-types';
import cameraOptionsType from '../../../../types/cameraOptions';
import { replaceSpinnerForPlayer } from '../../../../../../utils/vjs-utils';

export function MainCameraView(props) {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);

  const { options, onReady, isVisible = true, onUnmount } = props;

  useEffect(() => {
    if (!playerRef.current) {
      createPlayer();
    }
  }, [options]);

  function createPlayer() {
    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(
      document.createElement('video-js'),
    );

    // eslint-disable-next-line no-multi-assign
    const player = (playerRef.current = videojs(videoElement, options, () => {
      replaceSpinnerForPlayer(player);
      onReady(player);
    }));
  }

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => () => {
    const player = playerRef.current;

    if (player && !player.isDisposed()) {
      if (isVisible) {
        onUnmount(player.paused(), player.currentTime());
      }
      player.pause();
      player.dispose();
      playerRef.current = null;
    }
  }, []);

  return (
    <div
      className="camera"
      style={{ display: isVisible ? 'block' : 'none' }}
      data-testid="camera-view"
    >
      <div ref={placeholderRef} />
    </div>
  );
}

export default MainCameraView;

MainCameraView.propTypes = {
  options: cameraOptionsType.isRequired,
  onReady: func.isRequired,
  onUnmount: func,
  isVisible: bool,
};

MainCameraView.defaultProps = {
  isVisible: true,
  onUnmount: () => {},
};
