import useCountdown from '../../hooks/useCountdown';
import { getFormattedCountdownTime } from '../../utils/utils';

function PremiereCountdown({ timeToStart, onTimerEnd, endBefore }) {
  const timeLeft = useCountdown(timeToStart, endBefore);

  if (timeLeft <= 0) {
    if (onTimerEnd) onTimerEnd();
  }

  return getFormattedCountdownTime(timeLeft);
}

export default PremiereCountdown;
