import React, { memo } from 'react';
import { func, number, string } from 'prop-types';

import Room from '../Room';

function SecondaryVideo({ roomId, onReady, index }) {
  return (
    <Room
      roomId={roomId}
      key={roomId}
      onReady={(player) => onReady(player, index)}
    />
  );
}

export default memo(SecondaryVideo);

SecondaryVideo.propTypes = {
  roomId: string.isRequired,
  onReady: func.isRequired,
  index: number.isRequired,
};
