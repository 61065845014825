import { configureStore } from '@reduxjs/toolkit';

import playerReducer from './reducers/player';
import pollingReducer from './reducers/polling';
import episodeReducer from './reducers/episode';
import evidenceReducer from './reducers/evidence';

const reducer = {
  player: playerReducer,
  polling: pollingReducer,
  episode: episodeReducer,
  evidence: evidenceReducer,
};

export const setupStore = (preloadedState) => configureStore({
  reducer,
  preloadedState,
});
