import { useEffect, useState } from 'react';
import {
  EPISODE_ACTIVATION_DATA_UPDATE,
  STORAGE_UPDATE,
} from '../constants/eventNames';
import { EPISODES_KEY, SEASONS_KEY } from '../constants/localStorageKeys';
import Ticket from '../api/services/Ticket';

const isExpired = (expirationDate) => new Date().getTime() > new Date(expirationDate).getTime();

export default function useEpisodeActivationData(episodeId, seasonId) {
  const [isEpisodeActivated, setIsEpisodeActivated] = useState(null);

  const updateActivationState = () => {
    const episodes = JSON.parse(localStorage.getItem(EPISODES_KEY) || '{}');
    const seasons = JSON.parse(localStorage.getItem(SEASONS_KEY) || '{}');

    const isEpisodeTicketValid = !!episodes[episodeId] && !isExpired(episodes[episodeId]);
    const isSeasonTicketValid = !!seasons[seasonId] && !isExpired(seasons[seasonId]);

    setIsEpisodeActivated(isEpisodeTicketValid || isSeasonTicketValid);

    // remove ticket with expired session
    if (!!episodes[episodeId] && !isEpisodeTicketValid) {
      Ticket.invalidateEpisodeData(episodeId);
    }
    if (!!seasons[seasonId] && !isSeasonTicketValid) {
      Ticket.invalidateEpisodeData(null, seasonId);
    }
  };

  const handleStorageUpdate = (event) => {
    if (event.key === EPISODES_KEY || event.key === SEASONS_KEY) {
      updateActivationState();
    }
  };

  useEffect(() => {
    updateActivationState();
  }, [episodeId, seasonId]);

  useEffect(() => {
    window.addEventListener(EPISODE_ACTIVATION_DATA_UPDATE, updateActivationState);
    window.addEventListener(STORAGE_UPDATE, handleStorageUpdate);
    return () => {
      window.removeEventListener(EPISODE_ACTIVATION_DATA_UPDATE, updateActivationState);
      window.removeEventListener(STORAGE_UPDATE, handleStorageUpdate);
    };
  }, []);

  return isEpisodeActivated;
}
