/* eslint-disable no-console */

// Log levels: 'debug' | 'log' | 'warn' | 'error'
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'error';

const NO_OP = () => {};

export class ConsoleLogger {
  warn = NO_OP;

  log = NO_OP;

  error = NO_OP;

  debug = NO_OP;

  constructor(options = { level: 'error' }) {
    const { level } = options || {};

    this.error = console.error.bind(console);

    if (level === 'error') {
      return;
    }

    this.warn = console.warn.bind(console);

    if (level === 'warn') {
      return;
    }

    this.log = console.log.bind(console);

    if (level === 'log') {
      return;
    }

    this.debug = console.debug.bind(console);
  }
}

export const logger = new ConsoleLogger({ level: LOG_LEVEL });
