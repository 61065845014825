import React, { useMemo, useEffect, useState } from 'react';
import './WaitingRoom.scss';
import PropTypes from 'prop-types';
import CountdownVideo from './components/CountdownVideo/CountdownVideo';
import {
  setTimePassedFromEpisodeStart,
} from '../EpisodePlayer/services/TimeSync';
import { episodeModes } from '../../constants/episodeModes';
import { getCurrentTime } from '../../api/services/Timer';
import NoSignalVideo from './components/NoSignalVideo/NoSignalVideo';
import PremiereCover from './components/PremiereCover/PremiereCover';

const waitingRoomSteps = {
  PREMIERE_IMAGE: 'premiere_image',
  COUNTDOWN_VIDEO: 'countdown_video',
};

const NO_SIGNAL_VIDEOS_COUNT = 3;

function WaitingRoom({ episodeInfo, episodeMode }) {
  const [currentStep, setCurrentStep] = useState('');

  const onCountdownVideoShow = () => {
    // get actual server time to synchronize countdown video
    getCurrentTime().then((currentTime) => {
      setTimePassedFromEpisodeStart({
        serverTime: currentTime,
        episodeStartTime: episodeInfo.startTime,
      });
      setCurrentStep(waitingRoomSteps.COUNTDOWN_VIDEO);
    });
  };

  useEffect(() => {
    if (
      episodeMode === episodeModes.NOT_STARTED
      || episodeMode === episodeModes.COUNTDOWN
    ) {
      setCurrentStep(waitingRoomSteps.PREMIERE_IMAGE);
    }
    if (episodeMode === episodeModes.WAITING_ROOM) {
      onCountdownVideoShow();
    }
  }, [episodeMode]);

  const premiereCoverImage = useMemo(
    () => (
      <PremiereCover
        coverImage={episodeInfo?.coverImage}
        episodeStartTime={episodeInfo?.startTime}
      />
    ),
    [episodeInfo],
  );

  const memoizedCountdown = useMemo(
    () => (
      <CountdownVideo
        videoSource={
          episodeInfo?.premiereConfig?.waitingRoom?.countdownVideoSource
        }
        premiereCover={premiereCoverImage}
        duration={episodeInfo.premiereConfig.waitingRoom.countdownVideoDurationMin * 60}
      />
    ),
    [episodeInfo],
  );

  const noSignalVideos = useMemo(() => [...Array(NO_SIGNAL_VIDEOS_COUNT)].map((_, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <NoSignalVideo key={i} />
  )));

  return currentStep ? (
    <div className="videos waiting-room">
      <div className="waiting-room-wrapper">
        <div className="waiting-room-row">
          {currentStep === waitingRoomSteps.PREMIERE_IMAGE && (
          <div className="waiting-room-show-premiere">
            {premiereCoverImage}
          </div>
          )}
          {currentStep === waitingRoomSteps.COUNTDOWN_VIDEO && (
            <div className="waiting-room-memoized-countdown">
              {memoizedCountdown}
            </div>
          )}

          <div className="waiting-room-no-signal-wrapper">{noSignalVideos}</div>
        </div>
      </div>
    </div>
  ) : null;
}

WaitingRoom.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  episodeInfo: PropTypes.object.isRequired,
  episodeMode: PropTypes.string,
};

WaitingRoom.defaultProps = {
  episodeMode: '',
};

export default WaitingRoom;
