/* eslint-disable no-underscore-dangle */
import { playlistSelector } from './playlist-selector';

function safeGetComputedStyle(el, property) {
  if (!el) {
    return '';
  }
  const result = window.getComputedStyle(el);
  if (!result) {
    return '';
  }
  return result[property];
}

// see https://github.com/videojs/http-streaming/tree/v2.14.3#vhssystembandwidth
function getSystemBandwidth(bandwidth, throughput) {
  const invBandwidth = 1 / (bandwidth || 1);
  let invThroughput;
  if (throughput > 0) {
    invThroughput = 1 / throughput;
  } else {
    invThroughput = 0;
  }
  const systemBitrate = Math.floor(1 / (invBandwidth + invThroughput));
  return systemBitrate;
}

export function customBandwidthSelector() {
  let lastMediaRequestsTimedout = 0;

  let lastHighestSuccessfulBandwidth = 0;

  return function playlistSelectorWrapper() {
    const pixelRatio = this.useDevicePixelRatio ? window.devicePixelRatio || 1 : 1;

    const mediaRequestsTimedoutDiff = this.stats.mediaRequestsTimedout - lastMediaRequestsTimedout;

    if (mediaRequestsTimedoutDiff > 0 || this.bandwidth > lastHighestSuccessfulBandwidth) {
      // we should update bandwidth in two cases:
      // 1. if some chunks were timedout
      // (which means that current bandwidth is not enough and we need to drop quality)
      // 2. if new bandwidth is higher than current
      // (which means that we can increase quality)
      lastHighestSuccessfulBandwidth = this.bandwidth;
    }

    const systemBandwidth = getSystemBandwidth(lastHighestSuccessfulBandwidth
      || this.bandwidth, this.throughput);

    lastMediaRequestsTimedout = this.stats.mediaRequestsTimedout;

    return playlistSelector(
      this.playlists.master,
      systemBandwidth,
      parseInt(safeGetComputedStyle(this.tech_.el(), 'width'), 10) * pixelRatio,
      parseInt(safeGetComputedStyle(this.tech_.el(), 'height'), 10) * pixelRatio,
      this.limitRenditionByPlayerDimensions,
      this.masterPlaylistController_,
    );
  };
}
