import React, { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { number, shape, string } from 'prop-types';
import { SEASON_ID } from '../../../constants';
import usePremiereSettings from '../../../hooks/usePremiereSettings';
import { episodeModes } from '../../../constants/episodeModes';
import { getFormattedEpisodeStartTime } from '../../../utils/utils';
import PremiereCountdown from '../../PremiereCountdown';
import useEpisodeActivationData from '../../../hooks/useEpisodeActivationData';
import ActivateTicketButton from '../../ActivateTicket';

function EpisodeAccess({ episodeInfo }) {
  const {
    id,
    startTime: episodeStartTime,
    endTime: episodeEndTime,
  } = episodeInfo;
  const { episodeId } = useParams();
  const isEpisodeActivated = useEpisodeActivationData(id, SEASON_ID);
  const navigate = useNavigate();

  const { premiereConfig } = episodeInfo || {};

  const { mode, currentDateTime } = usePremiereSettings({
    episodeStartTime,
    episodeEndTime,
    showCountdownBeforeHr: premiereConfig.showCountdownBeforeHr,
    countdownVideoDurationMin: premiereConfig.waitingRoom.countdownVideoDurationMin,
  });

  const goToPlayer = () => {
    navigate(`/episode/${episodeId}/watch`);
  };

  const button = useMemo(() => {
    if (!isEpisodeActivated) {
      return (
        <ActivateTicketButton className="btn btn-danger" />
      );
    }

    switch (mode) {
      case episodeModes.ON_DEMAND:
        return (
          <button className="btn btn-outline-secondary" type="button" onClick={goToPlayer}>On demand</button>
        );
      case episodeModes.NOT_STARTED:
      case episodeModes.COUNTDOWN:
        return (
          <button className="btn btn-danger" type="button" onClick={goToPlayer}>Ready to solve the case</button>
        );
      case episodeModes.WAITING_ROOM:
      case episodeModes.LIVE:
        return (
          <button className="btn btn-danger" type="button" onClick={goToPlayer}>Solve now</button>
        );
      default:
        return null;
    }
  }, [mode, isEpisodeActivated, episodeId]);

  const timer = useMemo(() => {
    switch (mode) {
      case episodeModes.NOT_STARTED:
        return (
          <div className="episode-info-premiere-wrapper">
            <p className="episode-info-premiere-title">Live premiere</p>
            <p className="episode-info-premiere-date">{getFormattedEpisodeStartTime(episodeStartTime)}</p>
          </div>
        );
      case episodeModes.COUNTDOWN:
        return (
          <div className="episode-info-premiere-wrapper">
            <p className="episode-info-premiere-title">Live premiere</p>
            <p className="episode-info-premiere-date">
              <PremiereCountdown
                timeToStart={new Date(episodeStartTime) - currentDateTime}
                endBefore={premiereConfig.waitingRoom.countdownVideoDurationMin * 60000}
              />
            </p>
          </div>
        );
      case episodeModes.WAITING_ROOM:
      case episodeModes.LIVE:
        return <div className="btn btn-full-live">LIVE</div>;
      default:
        return null;
    }
  }, [mode, currentDateTime, episodeInfo]);

  return (
    <>
      {button}
      {timer}
    </>
  );
}

export default EpisodeAccess;

EpisodeAccess.propTypes = {
  episodeInfo: shape({
    id: string,
    startTime: string,
    endTime: string,
    premiereConfig: shape({
      showCountdownBeforeHr: number,
      waitingRoom: shape({
        countdownVideoDurationMin: number,
      }),
    }),
  }).isRequired,
};
