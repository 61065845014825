import React from 'react';
import { Link } from 'react-router-dom';

import Image from '../../../Image';

import './Logo.scss';

function Logo() {
  return (
    <Link
      className="site-logo"
      to="/"
      relative="path"
    >
      <picture>
        <source media="(min-width:576px)" srcSet="/logo.png" />
        <source media="(max-width:577px)" srcSet="/images/short-logo.png" />
        <Image
          src="/logo.png"
          alt="home"
          className="site-logo-img"
          srcset={[{ imageUrl: '/logo.webp' }]}
        />
      </picture>
    </Link>
  );
}
export default Logo;
