import {
  MAX_ELAPSED_OFFSET,
  PUSH_FORWARD_S,
  SYNC_ITERATIONS,
} from '../../config/video-sync-config';
import Events from '../../constants/events';
import { requestAnimFrame } from '../../services/TimeSync';
import NoSyncStrategy from '../NoSyncStrategy/NoSyncStrategy';

export default class SyncStrategy extends NoSyncStrategy {
  constructor() {
    super();
    this.prevPlayerTime = 0;
    this.prevElapsedTime = 0;
    this.nextOffsetForPlayerStart = 0;
    this.framesSyncronized = 0;
    this.isSeekingTimeoutActive = false;
    this.shouldCalculateNextOffsetForPlayerStart = true;
  }

  init(player) {
    if (this.player) {
      this.destroy();
    }
    this.player = player;
    this.timeupdateHandler = this.onTimeUpdate.bind(this);
    this.seekedHandler = this.onPlayerSeeked.bind(this);
    this.startHandler = this.onPlayerStart.bind(this);
    this.player.on(Events.TIME_UPDATE, this.timeupdateHandler);
    this.player.on(Events.SEEKED, this.seekedHandler);
    this.player.one(Events.PLAY, this.startHandler);
  }

  getTimeToSyncWith() {
    return this.player.getCurrentTime();
  }

  onPlayerStart() {
    // check if we are synchronized on start (which means we start playing from 00:00)
    // and if so, trigger CAUGHT_UP event
    const { isSynchronized } = this.getPlayerSyncState();
    if (isSynchronized) {
      this.player.trigger(Events.CAUGHT_UP);
    }
  }

  onPlayerSeeked() {
    const currentTime = this.player.getCurrentTime();
    if (!this.isPlayerAhead(currentTime)) {
      this.handleTimeUpdate(true);
    } else if (!this.isSeekingTimeoutActive) {
      this.player.pause();
      this.framesSyncronized = 0;
      this.setSeekingTimeout(currentTime);
    }
  }

  onTimeUpdate() {
    if (this.player.isPaused()) return;
    const playerTime = this.player.getCurrentTime();

    if (this.shouldCalculateNextOffsetForPlayerStart) {
      this.calculateNextOffsetForPlayerStart(playerTime);
    }

    this.handleTimeUpdate();
  }

  calculateNextOffsetForPlayerStart(playerTime) {
    const elapsedTime = this.getTimeToSyncWith();
    const playerTimeDiff = playerTime - this.prevPlayerTime;
    const elapsedTimeDiff = elapsedTime - this.prevElapsedTime;
    const timeSyncDelay = Math.abs(playerTimeDiff - elapsedTimeDiff);

    this.prevPlayerTime = playerTime;
    this.prevElapsedTime = elapsedTime;

    // need to reset the offset in case it is longer than 1 second
    this.setNextOffsetForPlayerStart(timeSyncDelay > PUSH_FORWARD_S ? 0 : timeSyncDelay);
  }

  handleTimeUpdate(startPlaying = false) {
    const { elapsedSeconds, timeDiff, isSynchronized } = this.getPlayerSyncState();

    if (!isSynchronized) {
      this.player.log('THE PLAYER IS OUT OF SYNC, timeDiff', timeDiff);
      this.player.isCaughtUp = false;
      this.framesSyncronized = 0;
      this.updateCurrentTime(elapsedSeconds);
    } else {
      if (startPlaying) {
        this.playIfAllowed();
      }
      this.framesSyncronized += 1;

      if (this.framesSyncronized === SYNC_ITERATIONS) {
        this.player.trigger(Events.CAUGHT_UP);
        this.shouldCalculateNextOffsetForPlayerStart = false;
      }
    }
  }

  getPlayerSyncState() {
    const elapsedSeconds = this.getTimeToSyncWith();

    const timeDiff = Math.abs(elapsedSeconds - this.player.getCurrentTime());

    return {
      elapsedSeconds,
      timeDiff,
      isSynchronized: timeDiff <= MAX_ELAPSED_OFFSET,
    };
  }

  updateCurrentTime(elapsedSeconds) {
    const currentTime = this.player.getCurrentTime();
    const timeDiff = elapsedSeconds - currentTime;
    const isFuture = timeDiff > 0;

    if (!isFuture) {
      this.player.log('PLAYER IS NOW AHEAD! by', timeDiff);

      const maxAllowedTimeDiff = PUSH_FORWARD_S + this.nextOffsetForPlayerStart;

      if (Math.abs(timeDiff) > maxAllowedTimeDiff) {
        this.player.log('REWIND BACKWARD TO', elapsedSeconds);
        this.player.setCurrentTime(this.getTimeToSyncWith());
        return;
      }

      // we are ahead, do nothing, just wait while the elapsed time will be the same
      if (this.isSeekingTimeoutActive) {
        return;
      }
      this.player.pause();

      this.player.log('START WAITING FOR SYNCHRONIZED TIME');
      this.setSeekingTimeout(currentTime);
      return;
    }

    this.pushForwardAndSynchronize(elapsedSeconds);
  }

  pushForwardAndSynchronize(elapsedSeconds) {
    const elapsedSeekingTime = PUSH_FORWARD_S + this.nextOffsetForPlayerStart;
    const currentTimeDiff = elapsedSeconds + elapsedSeekingTime;

    this.player.setCurrentTime(currentTimeDiff);
  }

  setSeekingTimeout(playerTime) {
    this.isSeekingTimeoutActive = true;
    requestAnimFrame(() => {
      if (this.getTimeToSyncWith() + this.nextOffsetForPlayerStart < playerTime) {
        this.setSeekingTimeout(playerTime);
      } else {
        this.player.log('CAUGHT UP WITH SYNCHRONIZED TIME -> playerTime', playerTime);
        this.isSeekingTimeoutActive = false;
        this.playIfAllowed();
      }
    });
  }

  isPlayerAhead(playerTime) {
    return playerTime > this.getTimeToSyncWith();
  }

  setNextOffsetForPlayerStart(value) {
    this.nextOffsetForPlayerStart = value;
  }

  destroy() {
    this.player.off(Events.TIME_UPDATE, this.timeupdateHandler);
    this.player.off(Events.SEEKED, this.seekedHandler);
    this.player.off(Events.PLAY, this.startHandler);
  }

  playIfAllowed() {
    this.prevPlayerTime = this.player.getCurrentTime();
    this.prevElapsedTime = this.getTimeToSyncWith();
    this.shouldCalculateNextOffsetForPlayerStart = true;

    this.player.playIfAllowed();
  }
}
