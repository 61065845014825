import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
import { func } from 'prop-types';
import cameraOptionsType from '../EpisodePlayer/types/cameraOptions';
import './HlsVideoPlayer.scss';
import { replaceSpinnerForPlayer } from '../../utils/vjs-utils';

export function HlsVideoPlayer(props) {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);

  const { options, onReady } = props;

  useEffect(() => {
    const player = playerRef.current;
    if (player && !player.isDisposed()) {
      player.dispose();
    }
    createPlayer();
  }, [options]);

  function createPlayer() {
    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(
      document.createElement('video-js'),
    );

    // eslint-disable-next-line no-multi-assign
    const player = (playerRef.current = videojs(videoElement, options, () => {
      if (options?.loadingSpinner) {
        replaceSpinnerForPlayer(player);
      }
      onReady(player);
    }));
  }

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => () => {
    const player = playerRef.current;
    if (player && !player.isDisposed()) {
      player.dispose();
      playerRef.current = null;
    }
  }, []);

  return (
    <div className="hls-video-player">
      <div ref={placeholderRef} />
    </div>
  );
}

export default HlsVideoPlayer;

HlsVideoPlayer.propTypes = {
  options: cameraOptionsType.isRequired,
  onReady: func,
};

HlsVideoPlayer.defaultProps = {
  onReady: () => {},
};
