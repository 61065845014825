import React from 'react';
import './EvidencePopup.scss';
import { arrayOf, func, shape, string } from 'prop-types';
import FullscreenPopup from '../../../../FullscreenPopup';
import EvidenceSlider from '../EvidenceSlider';
import Image from '../../../../Image';

export function EvidencePopup(props) {
  const { onClose, images } = props;
  return (
    <FullscreenPopup className="evidence-popup" onClose={onClose}>
      <EvidenceSlider
        images={images.map((image) => (
          <Image key={image.src} src={image.src} srcset={image.srcset} alt="Evidence paper picture" />
        ))}
      />
    </FullscreenPopup>
  );
}

export default EvidencePopup;

EvidencePopup.propTypes = {
  images: arrayOf(
    shape({
      src: string,
    }),
  ),
  onClose: func,
};

EvidencePopup.defaultProps = {
  images: [],
  onClose: () => {},
};
