import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useEpisodeData from '../../hooks/useEpisodeData';
import CastMemberCard from '../CastMemberCard';
import './EpisodeInfo.scss';
import Trailer from '../Trailer';
import EpisodeAccess from './EpisodeAccess';
import CardsSlider from '../CardsSlider';
import EpisodeCoverImagesCarousel from './EpisodeCoverImagesCarousel';
import Image from '../Image';

const sliderOptions = {
  fixedWidth: '70px',
  perMove: 1,
  focus: 'left',
  gap: 10,
};

function EpisodeInfo({ episodeId }) {
  const { episodeInfo } = useEpisodeData(episodeId);
  const [isTrailerFullScreen, setIsTrailerFullscreen] = useState(false);

  const toggleTrailer = () => {
    setIsTrailerFullscreen((prevState) => !prevState);
  };

  return (
    <div className="episode-info">
      {episodeInfo && (
        <div className="episode-info-body">
          <div className="episode-overlay" />
          <div className="episode-info-top-container">
            {episodeInfo?.trailer?.videoUrl && (
              <Trailer
                src={episodeInfo.trailer?.videoUrl}
                poster={episodeInfo.trailer?.poster}
                isFullScreen={isTrailerFullScreen}
                onPopupClose={toggleTrailer}
              />
            )}
            <Image
              className="episode-info-logo"
              src={episodeInfo.logo?.src}
              srcset={episodeInfo.logo?.srcset}
              alt={episodeInfo.name}
            />
            <div className="episode-info-buttons-box">
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={toggleTrailer}
              >
                Watch trailer
              </button>
              <EpisodeAccess episodeInfo={episodeInfo} />
            </div>
          </div>
          <div className="episode-info-bottom-container">
            <div className="episode-info-bottom-wrapper">
              <p className="episode-info-description">{episodeInfo.description}</p>
              <div className="episode-info-cast-members">
                <CardsSlider
                  cards={
                    episodeInfo.cast
                      ? episodeInfo.cast.map((castMember) => (
                        <CastMemberCard
                          key={castMember.cardImageUrl}
                          details={castMember}
                        />
                      ))
                      : []
                  }
                  options={sliderOptions}
                />
              </div>
            </div>
            <div className="episode-info-cover-slider">
              <EpisodeCoverImagesCarousel />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default EpisodeInfo;

EpisodeInfo.propTypes = {
  episodeId: PropTypes.string,
};

EpisodeInfo.defaultProps = {
  episodeId: '',
};
