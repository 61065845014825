/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  availableAt: null,
};

const evidenceSlice = createSlice({
  name: 'evidence',
  initialState,
  reducers: {
    setEvidenceAvailabilityTime(state, action) {
      state.availableAt = action.payload;
    },
  },
});

export const {
  setEvidenceAvailabilityTime,
} = evidenceSlice.actions;

export default evidenceSlice.reducer;
