import React, { useEffect } from 'react';

import Router from './pages/Router';
import LoginForm from './components/LoginForm';
import apiConfig from './config/api-config';
import './App.scss';

function App() {
  const [isLoggedIn, setIsLoggedIn] = React.useState(null);

  useEffect(() => {
    fetch(`${apiConfig.baseUrl}/token-verify`, { credentials: 'include' }).then(
      (response) => {
        setIsLoggedIn(response.status === 200);
      },
    );
  }, []);

  if (isLoggedIn === null) return null;

  return (
    <div className="app">
      {isLoggedIn ? (
        <Router />
      ) : (
        <LoginForm setIsLoggedIn={setIsLoggedIn} />
      )}
    </div>
  );
}

export default App;
