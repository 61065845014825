import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

import Episode from '../../../../api/services/Episode';
import EvidenceButton from './EvidenceButton';
import EvidencePopup from './EvidencePopup';

export function Evidence({ episodeId }) {
  const evidenceAvailableAt = useSelector((state) => state.evidence.availableAt);
  const currentTime = useSelector((state) => state.player.playedTime);

  const [isEvidenceEnabled, setIsEvidenceEnabled] = useState(false);
  const [isEvidencePopupOpened, setIsEvidencePopupOpened] = useState(false);
  const [evidenceImages, setEvidenceImages] = useState(false);

  useEffect(() => {
    if (evidenceAvailableAt === null) return;
    const isEnabled = evidenceAvailableAt < currentTime;
    setIsEvidenceEnabled(isEnabled);
  }, [evidenceAvailableAt, currentTime]);

  useEffect(() => {
    if (isEvidenceEnabled) {
      Episode.getEvidence(episodeId).then(({ images }) => {
        setEvidenceImages(images);
      });
    }
  }, [isEvidenceEnabled]);

  const openEvidence = () => {
    setIsEvidencePopupOpened(true);
  };

  const closeEvidence = () => {
    setIsEvidencePopupOpened(false);
  };

  return (
    <>
      <EvidenceButton onClick={openEvidence} isEnabled={isEvidenceEnabled && !!evidenceImages} />
      {isEvidencePopupOpened && <EvidencePopup images={evidenceImages} onClose={closeEvidence} />}
    </>
  );
}

export default Evidence;

Evidence.propTypes = {
  episodeId: string.isRequired,
};
