import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './SimplePopup.scss';

export function SimplePopup({ text, onClose }) {
  const [showPopup, setShowPopup] = useState(true);

  const onCloseAction = () => {
    setShowPopup(false);
    onClose();
  };

  useEffect(() => {
    document.body.classList.add('simple-popup-open');
    return () => {
      document.body.classList.remove('simple-popup-open');
    };
  }, []);

  return (
    showPopup
    && ReactDOM.createPortal(
      <div className="simple-popup" data-testid="simple-popup">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button className="btn btn-close" type="button" onClick={onCloseAction} />
        <div className="simple-popup-content">
          <p>{text}</p>
          <button
            type="button"
            onClick={onCloseAction}
            className="btn btn-outline-secondary"
          >
            Ok
          </button>
        </div>
      </div>,
      document.body,
    )
  );
}

export default SimplePopup;
