import React from 'react';
import { arrayOf, func, string } from 'prop-types';

import { isIOS } from 'react-device-detect';
import '../Camera.scss';
import { useSelector } from 'react-redux';
import MainCameraLive from './MainCameraLive';
import MainCameraOnDemand from './MainCameraOnDemand';
import MainCameraIOS from './MainCameraIOS';
import cameraOptionsType from '../../../types/cameraOptions';
import cameraObject from '../../../types/cameraObject';
import MainCameraIOSOnDemand from './MainCameraIOSOnDemand';

export function MainCamera(props) {
  const { options, onReady, activeCameraId, cameras, roomId } = props;
  const isLive = useSelector((state) => state.player.isLive);

  function getIOSCamera() {
    return isLive ? (
      <MainCameraIOS
        options={options}
        onReady={onReady}
        activeCameraId={activeCameraId}
        cameras={cameras}
      />
    ) : (
      <MainCameraIOSOnDemand
        options={options}
        onReady={onReady}
        activeCameraId={activeCameraId}
        cameras={cameras}
      />
    );
  }

  function getCamera() {
    const sources = cameras
      .filter((camera) => camera?.cameraId === activeCameraId)
      .map((camera) => camera.source);
    const modifiedOptions = { ...options, cameraId: activeCameraId, sources };

    return isLive
      ? <MainCameraLive options={modifiedOptions} onReady={onReady} roomId={roomId} />
      : <MainCameraOnDemand options={modifiedOptions} onReady={onReady} roomId={roomId} />;
  }

  return isIOS
    ? getIOSCamera()
    : getCamera();
}

export default MainCamera;

MainCamera.propTypes = {
  options: cameraOptionsType.isRequired,
  onReady: func.isRequired,
  cameras: arrayOf(cameraObject).isRequired,
  activeCameraId: string.isRequired,
  roomId: string.isRequired,
};
