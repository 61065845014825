export default {
  fullscreenToggle: false,
  seekToLive: false,
  pictureInPictureToggle: false,
  children: [
    // order is important for displaying controls in the correct order
    'progressControl',
    'playToggle',
    'volumePanel',
    'currentTimeDisplay',
    'timeDivider',
    'durationDisplay',
  ],
};
