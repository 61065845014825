let cache = {};

const CACHE_TTL_MS = 60000;

const getFromCache = (name) => {
  if (cache[name] && cache[name].date > new Date().getTime()) {
    return cache[name].data;
  }

  if (cache[name]) {
    delete cache[name];
  }

  return null;
};

const putInCache = (name, data) => {
  cache[name] = {
    data,
    date: new Date().getTime() + CACHE_TTL_MS,
  };
};

const clearCache = () => {
  cache = {};
};

export {
  getFromCache,
  putInCache,
  clearCache,
};
