import videojs from 'video.js';
import Events from '../constants/events';
import CustomPlayer from './CustomPlayer';
import { customBandwidthSelector } from '../lib/custom-bandwidth-selector';

export default class CustomPlayerVideoJS extends CustomPlayer {
  constructor(player, playerId, enableLogging = false) {
    super(player, playerId, enableLogging);

    this.isUnmuteButtonShown = false;
    this.isPlayButtonShown = false;

    const { vhs } = player.tech({ IWillNotUseThisInPlugins: true });
    if (vhs) {
      vhs.selectPlaylist = customBandwidthSelector();
    }
  }

  on(eventName, callback) {
    this.originalPlayer.on(eventName, callback);
  }

  off(eventName, callback) {
    if (this.originalPlayer) {
      this.originalPlayer.off(eventName, callback);
    }
  }

  one(eventName, callback) {
    this.originalPlayer.one(eventName, callback);
  }

  getCurrentTime() {
    return this.originalPlayer.currentTime();
  }

  setCurrentTime(value) {
    this.originalPlayer.currentTime(value);
  }

  isPaused() {
    return this.originalPlayer.paused();
  }

  trigger(eventName) {
    super.trigger(eventName);
    this.originalPlayer.trigger(eventName);
  }

  // player.volume(0.666)
  volume(percentAsDecimal) {
    if (typeof percentAsDecimal === 'number' && percentAsDecimal <= 1 && percentAsDecimal >= 0) {
      return this.originalPlayer.volume(percentAsDecimal);
    }
    return this.originalPlayer.volume();
  }

  volumeUp() {
    super.volumeUp();
    this.originalPlayer.volume(1);
  }

  volumeDown() {
    super.volumeDown();
    this.originalPlayer.volume(0);
  }

  get isMuted() {
    return this.originalPlayer.muted();
  }

  mute() {
    this.originalPlayer.muted(true);
  }

  unmute() {
    this.originalPlayer.muted(false);
  }

  handleNotAllowedError() {
    if (!this.originalPlayer.muted()) {
      this.originalPlayer.muted(true);
      this.setIsPlaying(true);
      this.showUnmuteButton();
    } else {
      this.showPlayButton();
    }
  }

  showPlayButton() {
    if (this.isPlayButtonShown) return;

    this.hideUnmuteButton();

    const button = this.createCustomButton(() => {
      this.play();
      this.hidePlayButton(button);
    }, 'vjs-big-custom-play-button');

    this.originalPlayer.addChild(button);
    this.isPlayButtonShown = true;
  }

  hidePlayButton(button) {
    if (!this.isPlayButtonShown) return;
    this.originalPlayer.removeChild(button);
    this.isPlayButtonShown = false;
  }

  showUnmuteButton() {
    if (this.isUnmuteButtonShown) return;

    this.hidePlayButton();

    const button = this.createCustomButton(() => {
      this.originalPlayer.muted(false);
      this.hideUnmuteButton(button);
    }, 'vjs-big-unmute-button');

    this.originalPlayer.addChild(button);
    this.isUnmuteButtonShown = true;
    this.trigger(Events.UNMUTE_BUTTON_SHOW);
  }

  hideUnmuteButton(button) {
    if (!this.isUnmuteButtonShown) return;
    this.originalPlayer.removeChild(button);
    this.isUnmuteButtonShown = false;
    this.trigger(Events.UNMUTE_BUTTON_HIDE);
  }

  createCustomButton(clickHandler, className, controlText) {
    const Button = videojs.getComponent('Button');

    return new Button(this.originalPlayer, {
      controlText,
      clickHandler,
      className: className ?? 'vjs-custom-button',
    });
  }

  hideControls() {
    this.originalPlayer.addClass('vjs-live-mode-controls');

    this.originalPlayer.on('contextmenu', (event) => {
      event.preventDefault();
    });
  }
}
