import React, { useEffect, useRef, useState } from 'react';
import videojs from 'video.js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomPlayerVideoJS from '../../../wrappers/CustomPlayerVideoJS';
import '../Camera.scss';
import SyncWithElapsedTimeStrategy from '../../../strategies/SyncWithElapsedTimeStrategy';
import cameraOptions from '../../../types/cameraOptions';
import Events from '../../../constants/events';

export function SecondaryCamera(props) {
  const placeholderRef = useRef(null);
  const playerRef = useRef(null);
  const customPlayerRef = useRef(null);
  const { options, onReady } = props;

  const isPlaying = useSelector((state) => state.player.isPlaying);
  const isLiveMode = useSelector((state) => state.player.isLive);

  const [isCameraSwitchInProgress, setIsCameraSwitchInProgress] = useState(false);

  useEffect(() => {
    setIsCameraSwitchInProgress(true);

    if (!playerRef.current) {
      createPlayer();
    } else if (playerRef.current && !customPlayerRef.current) {
      // the player started initializing but is not ready yet
      playerRef.current.dispose();
      createPlayer();
    } else {
      // the player is already initialized, we just need to update src
      updatePlayer();
    }
  }, [options]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => () => {
    const player = playerRef.current;
    const customPlayer = customPlayerRef.current;

    if (player && !player.isDisposed()) {
      player.dispose();
      playerRef.current = null;
    }

    if (customPlayer) {
      customPlayer.dispose();
      customPlayerRef.current = null;
    }
  }, []);

  function createPlayer() {
    const placeholderEl = placeholderRef.current;
    const videoElement = placeholderEl.appendChild(
      document.createElement('video-js'),
    );

    // eslint-disable-next-line no-multi-assign
    const player = (playerRef.current = videojs(videoElement, options, () => {
      onPlayerReady(player);
    }));
  }

  function onPlayerReady(player) {
    if (player) {
      createCustomPlayer(player);
    }
  }

  function createCustomPlayer(player) {
    const customPlayer = new CustomPlayerVideoJS(player, options.cameraId, false);
    if (isLiveMode) {
      customPlayer.setSyncStrategy(new SyncWithElapsedTimeStrategy());
      customPlayer.hideControls();
    }

    detectSyncCompletion(customPlayer);

    onReady(customPlayer);

    customPlayerRef.current = customPlayer;
  }

  function updatePlayer() {
    const player = playerRef.current;
    detectSyncCompletion(customPlayerRef.current);
    player.src(options.sources);
    customPlayerRef.current.playerId = options.cameraId;
    if (isPlaying) player.play();
  }

  function detectSyncCompletion(customPlayer) {
    if (customPlayer.isCaughtUp) {
      setIsCameraSwitchInProgress(false);
      return;
    }
    // we should hide overlay when we finished synchronizing with sync strategy
    customPlayer.one(Events.CAUGHT_UP, () => {
      setIsCameraSwitchInProgress(false);
    });
    customPlayer.one(Events.SEEKING, () => {
      // this is done for the case when we are somewhere ahead
      // and on pause and switched the camera
      if (playerRef.current?.paused()) {
        customPlayer.one(Events.SEEKED, () => {
          setIsCameraSwitchInProgress(false);
        });
      }
    });
  }

  return (
    <div className="camera">
      <div ref={placeholderRef} />
      {isCameraSwitchInProgress && (
        <div className="sync-overlay" />
      )}
    </div>
  );
}

export default SecondaryCamera;

SecondaryCamera.propTypes = {
  options: cameraOptions.isRequired,
  onReady: PropTypes.func.isRequired,
};
