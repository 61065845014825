import React, { memo } from 'react';
import { func, string } from 'prop-types';

import Room from '../Room';

function PrimaryVideo({ roomId, onReady }) {
  return (
    <Room
      roomId={roomId}
      onReady={onReady}
      isMain
    />
  );
}

export default memo(PrimaryVideo);

PrimaryVideo.propTypes = {
  roomId: string.isRequired,
  onReady: func.isRequired,
};
