import apiConfig from '../../config/api-config';
import { SEASON_ID } from '../../constants';

async function getEpisodeVideoData(episodeId) {
  return fetch(`${apiConfig.baseUrl}/episode/links`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      seasonId: SEASON_ID,
      episodeId,
    }),
    credentials: 'include',
  }).then((data) => data.json());
}

function getEpisodePublicData(episodeId) {
  return fetch(`${apiConfig.baseUrl}/episode/info`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      seasonId: SEASON_ID,
      episodeId,
    }),
  }).then((data) => data.json());
}

function getEpisodesPublicData() {
  const queryParams = new URLSearchParams({
    seasonId: encodeURIComponent(SEASON_ID),
  });
  return fetch(`${apiConfig.baseUrl}/episode/all?${queryParams}`, {
    credentials: 'include',
  }).then((data) => data.json());
}

async function getEvidence(episodeId) {
  return fetch(`${apiConfig.baseUrl}/episode/evidence`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      seasonId: SEASON_ID,
      episodeId,
    }),
    credentials: 'include',
  }).then((data) => data.json());
}

export default {
  getEpisodeVideoData,
  getEpisodePublicData,
  getEpisodesPublicData,
  getEvidence,
};
