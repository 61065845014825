import apiConfig from '../../config/api-config';
import { getFromCache, putInCache } from '../../utils/in-memory-cache';

const CACHE_KEY = 'server-time-diff';

const getNow = () => Date.now();

const getTimeDiff = (syncedServerTime) => syncedServerTime - getNow();

const getTimeWithDiff = (diff) => new Date(getNow() + diff);

let serverTimePromise = null;

const getServerTimePromise = () => {
  if (!serverTimePromise) {
    const requestStartTimestamp = getNow();
    serverTimePromise = fetch(`${apiConfig.baseUrl}/server-time?ct=${requestStartTimestamp}`, {
      method: 'get',
      credentials: 'include',
    }).then((data) => data.json())
      .then((data) => {
        const now = getNow();
        const totalReqResTime = now - requestStartTimestamp;

        let reqTime = data.diff;

        // if the client time is set manually and is ahead or behind current timezone,
        // we can't calculate latency because it will be incorrect
        // and may result in falsy enabling on-demand mode
        // while live is still in progress / not started
        // or falsy not starting episode when it's time
        if (reqTime < 0 || reqTime >= totalReqResTime) {
          // in this case we assume that request time is +- half of totalReqResTime
          reqTime = totalReqResTime / 2;
        }

        const resTime = totalReqResTime - reqTime;
        const syncedServerTime = data.serverTimestamp + resTime;
        const clientServerTimeDiff = getTimeDiff(syncedServerTime);
        putInCache(CACHE_KEY, clientServerTimeDiff);
        serverTimePromise = null;
        return data;
      });
  }

  return serverTimePromise;
};

const getServerTime = () => getServerTimePromise();

export const getCurrentTime = async (forceRequest = false) => {
  if (forceRequest || getFromCache(CACHE_KEY) === null) {
    await getServerTime();
  }

  return getTimeWithDiff(getFromCache(CACHE_KEY));
};
