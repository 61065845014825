import { shape, string } from 'prop-types';

export default shape({
  cameraId: string,
  cameraType: string,
  source: shape({
    src: string,
    type: string,
  }),
});
