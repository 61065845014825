import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { setTimePassedFromEpisodeStart } from '../services/TimeSync';
import { getCurrentTime } from '../../../api/services/Timer';

export function useEpisodeLiveTimer() {
  const [playerStartTime, setPlayerStartTime] = useState(0);
  const [isTimerReady, setIsTimerReady] = useState(false);

  const timerReadyTimeoutRef = useRef(null);

  const episodeStartTime = useSelector((state) => state.episode.startTime);

  const resetTimeout = () => {
    if (timerReadyTimeoutRef.current) {
      clearTimeout(timerReadyTimeoutRef.current);
    }
  };

  useEffect(() => resetTimeout, []);

  useEffect(() => {
    getCurrentTime(true).then((currentTime) => {
      const timeTillEpisodeStart = new Date(episodeStartTime).getTime() - currentTime;
      const startTime = performance.now();
      setPlayerStartTime(startTime);
      setTimePassedFromEpisodeStart({
        serverTime: currentTime,
        episodeStartTime,
        startTime,
      });

      if (timeTillEpisodeStart > 0) {
        // episode is not started yet, set timeout
        resetTimeout();
        timerReadyTimeoutRef.current = setTimeout(() => {
          setIsTimerReady(true);
        }, timeTillEpisodeStart);
      } else setIsTimerReady(true);
    });
  }, [episodeStartTime]);

  return { playerStartTime, isTimerReady };
}
