import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Image from '../Image';
import EpisodePremiereInfo from '../EpisodePremiereInfo';
import './EpisodeCard.scss';

function EpisodeCard({ info }) {
  const {
    episodeNumber,
    startTime,
    endTime,
    showCountdownBeforeHr,
    countdownVideoDurationMin,
    coverImage,
    caseName,
  } = info;

  const infoList = useMemo(
    () => (
      <>
        <li key={caseName}>
          <div className="episode-card-title">
            {caseName}
            <br />
            CASE
          </div>
        </li>
        <li key={`info-${startTime}`}>
          <EpisodePremiereInfo
            episodeStartTime={startTime}
            episodeEndTime={endTime}
            showCountdownBeforeHr={showCountdownBeforeHr}
            countdownVideoDurationMin={countdownVideoDurationMin}
          />
        </li>
      </>
    ),
    [startTime, endTime, showCountdownBeforeHr, countdownVideoDurationMin],
  );

  return (
    <Link className="episode-card" to={`/episode/e${episodeNumber}`}>
      <div className="episode-card-background">
        <div className="episode-card-description">
          <div className="episode-cover-picture-wrapper">
            <Image
              src={coverImage?.src}
              srcset={coverImage?.srcset}
              className="episode-cover-picture"
              alt="Episode cover"
            />
          </div>
          <ul>{infoList}</ul>
          <div className="btn btn-link-dark">
            Play
          </div>
        </div>
      </div>
    </Link>
  );
}
export default EpisodeCard;

EpisodeCard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  info: PropTypes.object,
};

EpisodeCard.defaultProps = {
  info: {},
};
