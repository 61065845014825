import React, { useState } from 'react';
import { string } from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import HowToPlayPopup from './components/HowToPlayPopup';

function HowToPlayButton({ className }) {
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();
  const [isPopupOpened, setIsPopupOpened] = useState(currentPath === '/how-to-play');

  const onClick = () => {
    setIsPopupOpened(true);
  };

  const onPopupClose = () => {
    if (currentPath === '/how-to-play') {
      navigate('/');
    }
    setIsPopupOpened(false);
  };

  return (
    <>
      <button className={className} type="button" onClick={onClick}>
        How To Play
      </button>
      {isPopupOpened && <HowToPlayPopup onClose={onPopupClose} />}
    </>
  );
}

export default HowToPlayButton;

HowToPlayButton.propTypes = {
  className: string,
};

HowToPlayButton.defaultProps = {
  className: '',
};
