import React, { useEffect, useMemo, useRef, useState } from 'react';
import { arrayOf, func, string } from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { disableMainCameraSwitch, enableMainCameraSwitch, mute, unmute } from '../../../../../../reducers/player';

import SyncWithElapsedTimeStrategy from '../../../../strategies/SyncWithElapsedTimeStrategy';
import CustomPlayerVideoJS from '../../../../wrappers/CustomPlayerVideoJS';
import NoSyncStrategy from '../../../../strategies/NoSyncStrategy';
import cameraOptions from '../../../../types/cameraOptions';
import cameraObject from '../../../../types/cameraObject';
import Events from '../../../../constants/events';

import MainCameraView from '../MainCameraView';

export function MainCameraIOS(props) {
  const dispatch = useDispatch();

  const customPlayerRef = useRef(null);
  const playersRef = useRef([null, null, null]);
  const [customPlayers, setCustomPlayers] = useState([null, null, null]);
  const { options, onReady, cameras, activeCameraId } = props;

  const [isReady, setIsReady] = useState(false);

  const isLive = useSelector((state) => state.player.isLive);
  const isMuted = useSelector((state) => state.player.isMuted);

  const muteIfRequired = (player) => {
    if (!player?.isDisposed?.()) {
      if (isMuted) player.mute?.();
      else player.unmute?.();
    }
  };

  useEffect(() => {
    customPlayerRef.current = null;
    customPlayers.forEach((customPLayer) => {
      customPLayer?.dispose();
    });

    setCustomPlayers([null, null, null]);
    setIsReady(false);
  }, [cameras]);

  useEffect(() => {
    if (!customPlayers.some((player) => player == null)) {
      setIsReady(true);
      // eslint-disable-next-line prefer-destructuring
      customPlayerRef.current = customPlayers[getCameraIndexById(activeCameraId)];
      setSyncStrategy(customPlayerRef.current);
      customPlayerRef.current.volumeUp();
      onReady(customPlayerRef.current);

      customPlayerRef.current.on(Events.SEEKING, () => {
        dispatch(disableMainCameraSwitch());
      });

      customPlayerRef.current.on(Events.CAUGHT_UP, () => {
        dispatch(enableMainCameraSwitch());
      });
    }
  }, [customPlayers]);

  useEffect(() => {
    const player = customPlayers[getCameraIndexById(activeCameraId)];
    if (player) {
      muteIfRequired(player);
    }
  }, [isMuted, activeCameraId]);

  function setSyncStrategy(customPlayer) {
    const strategy = isLive ? new SyncWithElapsedTimeStrategy() : new NoSyncStrategy();
    customPlayer.setSyncStrategy(strategy);
  }

  const onPlayerReady = (player, index) => {
    if (player) {
      playersRef.current[index] = player;
      const customPlayer = new CustomPlayerVideoJS(player, cameras[index].cameraId, true);
      customPlayer.mute();

      subscribeOnUnmuteButton(customPlayer);
      muteIfRequired(customPlayer);

      setCustomPlayers((prevState) => {
        const newState = [...prevState];
        newState[index] = customPlayer;
        return newState;
      });
    }
  };

  useEffect(() => {
    if (!isReady) return;

    const activeCameraIndex = getCameraIndexById(activeCameraId);
    const newCustomPlayer = customPlayers[activeCameraIndex];

    if (customPlayerRef.current) {
      initNewPlayerInsteadOfCurrent(newCustomPlayer);
    }
  }, [activeCameraId]);

  useEffect(() => () => {
    const customPlayer = customPlayerRef.current;

    if (customPlayer) {
      customPlayer.dispose();
      customPlayerRef.current = null;
    }
  }, []);

  function getCameraIndexById(cameraId) {
    const index = cameras
      .findIndex((camera) => camera.cameraId === cameraId);
    return index > 0 ? index : 0;
  }

  function destroyOldPlayer() {
    customPlayerRef.current.mute();
    customPlayerRef.current.setIsPlaying(false);
  }

  function initNewPlayerInsteadOfCurrent(customPlayer) {
    customPlayer.one(Events.SEEKED, () => {
      setSyncStrategy(customPlayer);
      onReady(customPlayer);
    });

    customPlayer.one(Events.CAUGHT_UP, () => {
      dispatch(enableMainCameraSwitch());
    });

    const isPaused = customPlayerRef.current.isPaused();
    destroyOldPlayer();
    customPlayer.setIsPlaying(!isPaused, true);
    customPlayer.setCurrentTime(customPlayerRef.current.getCurrentTime());
    customPlayerRef.current = customPlayer;
    customPlayer.volumeUp();
  }

  function subscribeOnUnmuteButton(customPlayer) {
    customPlayer.on(Events.UNMUTE_BUTTON_SHOW, () => {
      dispatch(mute());
    });
    customPlayer.on(Events.UNMUTE_BUTTON_HIDE, () => {
      dispatch(unmute());
    });
  }

  const memoizedCameras = useMemo(() => (
    <div>
      {cameras.map((camera, index) => (
        <MainCameraView
          key={camera.cameraId}
          isVisible={camera.cameraId === activeCameraId}
          options={{ ...options, sources: [camera.source] }}
          onReady={(player) => onPlayerReady(player, index)}
        />
      ))}
    </div>
  ), [cameras, options, activeCameraId]);

  return memoizedCameras;
}

export default MainCameraIOS;

MainCameraIOS.propTypes = {
  options: cameraOptions.isRequired,
  onReady: func.isRequired,
  cameras: arrayOf(cameraObject),
  activeCameraId: string.isRequired,
};
