import React, { useEffect, useRef, useState } from 'react';
import { node, number, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomPlayerVideoJS from '../../../EpisodePlayer/wrappers/CustomPlayerVideoJS';
import videoPlayerConfig from '../../../EpisodePlayer/config/video-player-config';
import disabledControlsConfig from '../../../EpisodePlayer/config/video-player-disabled-controls-config';
import HlsVideoPlayer from '../../../HlsVideoPlayer/HlsVideoPlayer';
import Events from '../../../EpisodePlayer/constants/events';

import './CountdownVideo.scss';
import SyncWithCountdownElapsedTimeStrategy
  from '../../../EpisodePlayer/strategies/SyncWithCountdownElapsedTimeStrategy';
import { mute, unmute } from '../../../../reducers/player';

function CountdownVideo(props) {
  const { videoSource, premiereCover, duration } = props;

  const dispatch = useDispatch();

  const [options, setOptions] = useState(null);

  const [isSyncInProgress, setIsSyncInProgress] = useState(true);

  const isMuted = useSelector((state) => state.player.isMuted);

  const customPlayerRef = useRef(null);

  useEffect(
    () => () => {
      if (customPlayerRef.current) {
        customPlayerRef.current.dispose();
      }
    },
    [],
  );

  useEffect(() => {
    if (videoSource) {
      setOptions({
        ...videoPlayerConfig,
        height: 720,
        loadingSpinner: false,
        controls: false,
        controlBar: disabledControlsConfig,
        playsinline: true,
        sources: [videoSource],
        autoplay: true,
      });
    }
  }, [videoSource]);

  useEffect(() => {
    muteIfRequired(customPlayerRef?.current);
  }, [isMuted]);

  const onPlayerReady = (player) => {
    if (player) {
      const customPlayer = new CustomPlayerVideoJS(player, 'countdown-video', true);
      customPlayer.setSyncStrategy(new SyncWithCountdownElapsedTimeStrategy(duration));
      customPlayer.setIsPlaying(true);

      customPlayer.one(Events.CAUGHT_UP, () => {
        setIsSyncInProgress(false);
        // video should be unmuted only when it is synchronized
        customPlayer.volumeUp();

        // volumeUp can cause the pausing of video if the audio is blocked by browser
        // so this case should be handled the same way as not allowed error,
        // and unmute button should be shown
        if (customPlayer.isPaused()) {
          customPlayer.setIsPlaying(false);
          customPlayer.handleNotAllowedError();
        }
      });

      subscribeOnPlayerUnmuteButton(customPlayer);
      muteIfRequired(customPlayer);

      customPlayerRef.current = customPlayer;
    }
  };

  function muteIfRequired(customPlayer) {
    if (!customPlayer) {
      return;
    }
    if (isMuted) customPlayer.mute();
    else customPlayer.unmute();
  }

  function subscribeOnPlayerUnmuteButton(customPlayer) {
    customPlayer.on(Events.UNMUTE_BUTTON_SHOW, () => {
      dispatch(mute());
    });
    customPlayer.on(Events.UNMUTE_BUTTON_HIDE, () => {
      dispatch(unmute());
    });
  }

  return (
    options && (
      <div className="countdown-video">
        <HlsVideoPlayer options={options} onReady={onPlayerReady} />
        {isSyncInProgress && (
          <div className="premiere-overlay">
            <div className="waiting-room-show-premiere">
              {premiereCover}
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default CountdownVideo;

CountdownVideo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  videoSource: object.isRequired,
  premiereCover: node.isRequired,
  duration: number.isRequired,
};
