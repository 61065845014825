import React, { useState } from 'react';
import { number, shape, string, arrayOf } from 'prop-types';

import Image from '../Image';
import './CastMemberCard.scss';
import FullscreenPopup from '../FullscreenPopup';

function CastMemberCard(props) {
  const {
    details: { cardImage, detailsImage, title },
  } = props;
  const [isDetailsImageShown, setIsDetailsImageShown] = useState(false);

  const toggleCastMemberDetails = () => {
    setIsDetailsImageShown((prevState) => !prevState);
  };

  return (
    <>
      <div
        className="cast-member-card"
        onClick={toggleCastMemberDetails}
        role="presentation"
        data-testid="cast-member-card"
      >
        <Image src={cardImage?.src} srcset={cardImage?.srcset} alt={title} />
      </div>
      {isDetailsImageShown && (
        <FullscreenPopup className="cast-member-popup" onClose={toggleCastMemberDetails}>
          <div className="popup-overlays">
            <div className="popup-overlay-background" />
            <div className="popup-overlay-gradient" />
          </div>
          <div className="cast-member-popup-content-box">
            <Image
              src={detailsImage?.src}
              srcset={detailsImage?.srcset}
              alt={title}
            />
          </div>
        </FullscreenPopup>
      )}
    </>
  );
}

export default CastMemberCard;

CastMemberCard.propTypes = {
  details: shape({
    title: string,
    cardImage: shape({
      src: string,
      srcset: arrayOf(
        shape({
          imageUrl: string,
          width: number,
        }),
      ),
    }),
    detailsImage: shape({
      src: string,
      srcset: arrayOf(
        shape({
          imageUrl: string,
          width: number,
        }),
      ),
    }),
  }).isRequired,
};
