export default {
  PLAY: 'play',
  PAUSE: 'pause',
  TIME_UPDATE: 'timeupdate',
  SEEKING: 'seeking',
  SEEKED: 'seeked',
  LOADED_METADATA: 'loadedmetadata',
  CAUGHT_UP: 'custom.caughtup',
  VOLUME_CHANGE: 'volumechange',
  UNMUTE_BUTTON_SHOW: 'custom.unmute.button.show',
  UNMUTE_BUTTON_HIDE: 'custom.unmute.button.hide',
};
