import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { mute, unmute } from '../../../../reducers/player';
import './MuteButton.scss';

export function MuteButton() {
  const dispatch = useDispatch();

  const isMuted = useSelector((state) => state.player.isMuted);

  const muteToggle = () => {
    if (isMuted) {
      dispatch(unmute());
    } else {
      dispatch(mute());
    }
  };

  return (
    <button aria-label="mute" className={`btn btn-outline-secondary mute ${isMuted ? 'mute' : 'unmute'}-state`} type="button" onClick={muteToggle}>
      {isMuted
        ? <img className="icon" src="/images/icons/mute-icon.svg" alt="mute" />
        : <img className="icon" src="/images/icons/unmute-icon.svg" alt="unmute" />}
    </button>
  );
}
