import React from 'react';
import { RouterProvider, createHashRouter } from 'react-router-dom';

import Home from './Home';
import Episode from './Episode';
import EpisodeWatch from './EpisodeWatch';
import About from './About';
import FAQ from './FAQ';
import ErrorBoundary from '../components/ErrorBoundary/ErrorBoundary';

function Router() {
  return (
    <RouterProvider router={createHashRouter([
      {
        path: '',
        element: <Home />,
        title: 'home',
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'tickets',
        element: <Home />,
        title: 'home',
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'how-to-play',
        element: <Home />,
        title: 'home',
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'episode/:episodeId',
        element: <Episode />,
        title: 'episode',
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'episode/:episodeId/watch',
        element: <EpisodeWatch />,
        title: 'episode-watch',
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'about',
        element: <About />,
        title: 'about',
        errorElement: <ErrorBoundary />,
      },
      {
        path: 'faq',
        element: <FAQ />,
        title: 'faq',
        errorElement: <ErrorBoundary />,
      },
    ])}
    />
  );
}

export default Router;
