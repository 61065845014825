import React, { useState } from 'react';
import { string } from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';

import ActivateTicketPopup from './components/ActivateTicketPopup';

function ActivateTicketButton({ className }) {
  const currentPath = useLocation().pathname;
  const navigate = useNavigate();
  const [isPopupOpened, setIsPopupOpened] = useState(currentPath === '/tickets');

  const onTicketsButtonClick = () => {
    setIsPopupOpened(true);
  };

  const onPopupClose = () => {
    if (currentPath === '/tickets') {
      navigate('/');
    }
    setIsPopupOpened(false);
  };

  return (
    <>
      <button className={className} type="button" onClick={onTicketsButtonClick}>
        Tickets
      </button>
      {isPopupOpened && <ActivateTicketPopup onClose={onPopupClose} />}
    </>
  );
}

export default ActivateTicketButton;

ActivateTicketButton.propTypes = {
  className: string,
};

ActivateTicketButton.defaultProps = {
  className: '',
};
