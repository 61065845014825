import React, { useRef, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { setPlayedTime } from '../../../../reducers/player';

import SyncWithPlayerOnDemandStrategy from '../../strategies/SyncWithPlayerOnDemandStrategy';
import Events from '../../constants/events';
import '../../EpisodePlayer.scss';

import SecondaryVideos from '../SecondaryVideos';
import PrimaryVideo from '../PrimaryVideo';

function EpisodePlayerOnDemand({ mainConfig, secondaryConfigs }) {
  const [mainPlayerReady, setMainPlayerReady] = useState(false);

  const mainPlayerRef = useRef(null);
  const secondaryPlayersRef = useRef([null, null, null]);

  const dispatch = useDispatch();

  const onSecondaryPlayerReady = useCallback((player, index) => {
    const mainPlayer = mainPlayerRef.current;
    if (mainPlayer && !mainPlayer.isDisposed()) {
      player.setSyncStrategy(new SyncWithPlayerOnDemandStrategy(mainPlayer));
    }

    secondaryPlayersRef.current[index] = player;
  }, []);

  const onMainPlayerReady = useCallback((player) => {
    mainPlayerRef.current = player;

    const mainPlayer = mainPlayerRef.current;

    if (mainPlayer) {
      setMainPlayerReady(true);

      mainPlayer.on(Events.TIME_UPDATE, () => {
        dispatch(setPlayedTime(mainPlayer.getCurrentTime()));
      });

      const secondaryPlayers = secondaryPlayersRef.current;

      secondaryPlayers.forEach((secondaryPlayer) => {
        if (secondaryPlayer) {
          secondaryPlayer.destroySyncStrategy();
          secondaryPlayer.setSyncStrategy(new SyncWithPlayerOnDemandStrategy(mainPlayer));
        }
      });
    }
  }, []);

  return (
    <div className="videos episode-players" data-testid="videos">
      <div className="episode-players-wrapper">
        {mainConfig?.roomId ? (
          <PrimaryVideo
            roomId={mainConfig.roomId}
            onReady={onMainPlayerReady}
          />
        ) : null}
        <div className="secondary">
          {mainPlayerReady ? (
            <SecondaryVideos
              configs={secondaryConfigs}
              onReady={onSecondaryPlayerReady}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default EpisodePlayerOnDemand;

EpisodePlayerOnDemand.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  mainConfig: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  secondaryConfigs: PropTypes.array,

};

EpisodePlayerOnDemand.defaultProps = {
  mainConfig: null,
  secondaryConfigs: [],
};
