import React, { useEffect, useState } from 'react';

import apiConfig from '../../config/api-config';
import NavigationMenu from '../../components/NavigationMenu';

import Image from '../../components/Image';
import MakingOfVideoPopup from './components/MakingOfVideoPopup/MakingOfVideoPopup';
import PinImage from './components/PinImage';
import './About.scss';

function About() {
  const [isMakingOfPopupOpened, setIsMakingOfPopupPopupOpened] = useState(false);
  const [sections, setSections] = useState([]);

  useEffect(() => {
    fetch(`https://${apiConfig.videoCDN}/assets/public/texts/about-us.json`)
      .then((response) => response.json())
      .then((data) => {
        setSections(data);
      });
  }, []);

  const mainDescription = sections.map((section, i) => (
    <>
      {/* eslint-disable-next-line react/no-array-index-key */}
      <p className="section-title" key={`about-us-section-title-${i}`}>{section.title}</p>
      {section?.paragraphs?.map((paragraph, j) => (
        // eslint-disable-next-line react/no-array-index-key
        <p key={`about-us-section-p-${i}-${j}`}>{paragraph}</p>
      ))}
    </>
  ));

  const onClick = () => {
    setIsMakingOfPopupPopupOpened(true);
  };

  const onPopupClose = () => {
    setIsMakingOfPopupPopupOpened(false);
  };

  return (
    <div className="about-us-page">
      <NavigationMenu />
      <div className="about-us-content">
        <div className="about-us-header">
          <PinImage />
          <Image
            src="/images/about-us/top-paper.png"
            alt="bout us header"
            srcset={[{ imageUrl: '/images/about-us/top-paper.webp' }]}
          />
        </div>
        <main className="about-us-article-content">
          <PinImage />
          <article className="about-us-article">{mainDescription}</article>
        </main>
        <aside className="about-us-aside">
          <div className="about-us-aside-background">
            <PinImage />
            <button type="button" onClick={onClick}>
              <Image
                className="about-us-aside-image"
                src="/images/about-us/testing-side-image.png"
                alt="Making of picture"
                srcset={[{ imageUrl: '/images/about-us/testing-side-image.webp' }]}
              />
            </button>
            <p className="about-us-aside-paragraph">The making of Foul Play.</p>
          </div>
        </aside>
      </div>
      {isMakingOfPopupOpened && <MakingOfVideoPopup onClose={onPopupClose} />}
    </div>
  );
}

export default About;
