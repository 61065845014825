import React from 'react';

import Image from '../../../components/Image';

export default function PinImage() {
  return (
    <div className="pin-box">
      <Image
        className="pin"
        src="/images/papers-and-pushpin/red-pushpin.png"
        alt="Pin"
        srcset={[{ imageUrl: '/images/papers-and-pushpin/red-pushpin.webp' }]}
      />
    </div>
  );
}
