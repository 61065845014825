import React from 'react';
import PropTypes, { node } from 'prop-types';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './CardSlider.scss';

const defaultOptions = {
  perMove: 1,
  focus: 'center',
  pagination: false,
  gap: 0,
  clones: 0,
  mediaQuery: 'min',
};

function CardsSlider({ cards, options }) {
  const sliderOptions = {
    ...defaultOptions,
    ...options,
  };

  return (
    <Splide className="cards-slider" options={sliderOptions}>
      {cards.map((card, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SplideSlide key={`splide-slide-${index}`} data-testid="splide-slide">
          {card}
        </SplideSlide>
      ))}
    </Splide>
  );
}
export default CardsSlider;

CardsSlider.propTypes = {
  cards: PropTypes.arrayOf(node),
  options: PropTypes.shape({}),
};

CardsSlider.defaultProps = {
  cards: [],
  options: {},
};
