import React from 'react';
import { func } from 'prop-types';

import controlBarConfig from '../../../../config/control-bar-config';
import apiConfig from '../../../../config/api-config';
import FullscreenPopup from '../../../../components/FullscreenPopup';
import HlsVideoPlayer from '../../../../components/HlsVideoPlayer';

const videoOptions = {
  enableSourceset: true,
  loop: true,
  controlBar: controlBarConfig,
  loadingSpinner: false,
  controls: true,
  bigPlayButton: true,
  sources: [{
    src: `https://${apiConfig.videoCDN}/public-video-assets/public/making_of/playlist.m3u8`,
    type: 'application/x-mpegURL',
  }],
};

function MakingOfVideoPopup({ onClose }) {
  return (
    <FullscreenPopup onClose={onClose}>
      <HlsVideoPlayer options={videoOptions} />
    </FullscreenPopup>
  );
}

export default MakingOfVideoPopup;

MakingOfVideoPopup.propTypes = {
  onClose: func.isRequired,
};
