import { useEffect, useState } from 'react';

export function useCameraSource(cameras, activeCameraId) {
  const [sources, setSources] = useState();

  useEffect(() => {
    setSources(cameras
      .filter(({ cameraId }) => cameraId === activeCameraId)
      .map(({ source }) => source));
  }, [cameras, activeCameraId]);

  return { sources };
}
