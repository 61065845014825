import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import AlwaysDisplayedLinks from './components/AlwaysDisplayedLinks';
import FoldableLinks from './components/FoldableLinks';
import Logo from './components/Logo';

import './NavigationMenu.scss';

function NavigationMenu() {
  const currentPath = useLocation().pathname;
  const pathMainPart = currentPath.split('/')[1];
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, [setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isOpen]);

  return (
    <div className="navigation-menu">
      <Logo />
      <div className="menu-items-container">
        <FoldableLinks pathMainPart={pathMainPart} isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <AlwaysDisplayedLinks pathMainPart={pathMainPart} toggleSidebar={toggleSidebar} />
      </div>
    </div>
  );
}

export default NavigationMenu;
