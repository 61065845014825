let timePassedFromEpisodeStart = 0;

export const requestAnimFrame = (function customRequestAnimFrame(window) {
  const suffix = 'equestAnimationFrame';
  const rAF = ['r', 'webkitR', 'mozR', 'msR', 'oR'].filter(
    (val) => val + suffix in window,
  )[0] + suffix;

  return (
    window[rAF]
    || function fallbackRequestAnimFrame(callback) {
      window.setTimeout(() => {
        callback(+new Date());
      }, 1000 / 60);
    }
  );
}(window));

export function setTimePassedFromEpisodeStart({
  episodeStartTime,
  serverTime,
}) {
  const serverTimeMs = new Date(serverTime).getTime();
  const episodeStartTimeMs = new Date(episodeStartTime).getTime();
  const result = serverTimeMs - episodeStartTimeMs - getNow();

  timePassedFromEpisodeStart = result;
}

export function getElapsed(timestamp) {
  const now = timestamp || getNow();
  return now + timePassedFromEpisodeStart;
}

export function getElapsedSeconds(timestamp) {
  return getElapsed(timestamp) / 1000;
}

export function isPlayerAhead(playerTime) {
  return playerTime > getElapsedSeconds();
}

function getNow() {
  return performance.now();
}
