import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import './Camera.scss';
import { shallowEqual, useSelector } from 'react-redux';
import MainCamera from './MainCamera';
import SecondaryCamera from './SecondaryCamera';
import { useCameraSource } from '../../hooks/useCameraSource';
import cameraOptions from '../../types/cameraOptions';

export function Camera(props) {
  const { onReady, isMain, activeCameraId, roomId, cameras } = props;

  const options = useSelector((state) => (isMain
    ? state.player.mainCameraOptions
    : state.player.secondaryCameraOptions), shallowEqual);

  const { sources } = useCameraSource(cameras, activeCameraId);

  const memoizedMainCamera = useMemo(() => (
    options && (
      <MainCamera
        options={options}
        onReady={onReady}
        activeCameraId={activeCameraId}
        cameras={cameras}
        roomId={roomId}
      />
    )
  ), [options, activeCameraId, cameras, roomId]);

  const memoizedSecondaryCamera = useMemo(() => (
    <SecondaryCamera
      options={{ ...options, sources, cameraId: activeCameraId }}
      onReady={onReady}
    />
  ), [options, sources, activeCameraId, onReady]);

  return isMain
    ? memoizedMainCamera
    : memoizedSecondaryCamera;
}

export default memo(Camera);

Camera.propTypes = {
  options: cameraOptions.isRequired,
  onReady: PropTypes.func.isRequired,
};

Camera.defaultProps = {
  options: {},
};
