import React from 'react';
import './ErrorBoundary.scss';

function ErrorBoundary() {
  return (
    <div className="error-boundary-message">
      Something went wrong.
      <br />
      Please try to reload the page.
    </div>
  );
}

export default ErrorBoundary;
