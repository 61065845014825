import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Ticket from '../../api/services/Ticket';

function TicketValidationForm({ onActivate }) {
  const [activationCode, setActivationCode] = useState('');
  const [isValid, setIsValid] = useState();

  const handleChange = (event) => {
    setActivationCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationInfo = await Ticket.validate(activationCode);

    if (validationInfo.isValid) {
      Ticket.updateValidatedEpisodesData(validationInfo);
      onActivate(validationInfo);
    }

    setIsValid(validationInfo.isValid);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <input
            className="activate-ticket-form-input"
            type="text"
            value={activationCode}
            onChange={handleChange}
            placeholder="Ticket code"
          />
        </div>
        <div>
          <input className="btn btn-outline-dark" type="submit" value="Activate" disabled={!activationCode} />
        </div>
      </form>
      {isValid === false && <div>Ticket code is not valid</div>}
    </div>
  );
}
export default TicketValidationForm;

TicketValidationForm.propTypes = {
  onActivate: PropTypes.func,
};

TicketValidationForm.defaultProps = {
  onActivate: () => {},
};
