import React from 'react';
import { createRoot } from 'react-dom/client';
import videojs from 'video.js';
import Spinner from './Spinner';

const VideoJSComponent = videojs.getComponent('Component');

class VideoPlayerSpinner extends VideoJSComponent {
  constructor(player, options = {}) {
    super(player, options);

    this.root = createRoot(this.el());
    this.mountReactComponent = this.mountReactComponent.bind(this);

    player.ready(() => this.mountReactComponent());
  }

  mountReactComponent() {
    this.root.render(
      <div className="vjs-player-spinner">
        <Spinner />
      </div>,
    );
  }

  // remove the React root when spinner component is destroyed
  dispose() {
    super.dispose();
    setTimeout(() => this.root.unmount());
  }
}

export default VideoPlayerSpinner;
