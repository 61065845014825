import React, { useState, useEffect } from 'react';

import Season from '../../api/services/Season';

import NavigationMenu from '../../components/NavigationMenu';
import CastMemberCard from '../../components/CastMemberCard';
import EpisodeCards from '../../components/EpisodeCards';
import CardsSlider from '../../components/CardsSlider';
import Trailer from '../../components/Trailer';

import HomeFooter from './HomeFooter';
import './Home.scss';

const sliderOptions = {
  padding: { left: 20 },
  fixedWidth: '150px',
  perPage: 1,
  focus: 'left',
  omitEnd: true,
  gap: '1rem',
  breakpoints: {
    576: {
      gap: '1.5rem',
    },
    768: {
      padding: { left: 60 },
    },
    992: {
      padding: { left: 80 },
    },
    1200: {
      padding: { left: 100 },
      fixedWidth: '200px',
      gap: '2rem',
    },
    1440: {
      padding: { left: 120 },
    },
    1800: {
      fixedWidth: '250px',
    },
  },
};

function Home() {
  const [isTrailerFullScreen, setIsTrailerFullscreen] = useState(false);
  const [seasonTrailer, setSeasonTrailer] = useState();
  const [castList, setCastlist] = useState([]);

  const toggleTrailer = () => {
    setIsTrailerFullscreen((prevState) => !prevState);
  };

  useEffect(() => {
    const isPortraitOrientation = window.innerWidth < window.innerHeight;
    Season.getSeasonData().then((data) => {
      setSeasonTrailer(isPortraitOrientation ? data?.seasonTrailerPortrait : data?.seasonTrailer);
      setCastlist(data.cast || []);
    });
  }, []);

  return (
    <div className="home-page">
      <NavigationMenu />
      <div className="trailer-container">
        {seasonTrailer?.videoUrl ? (
          <Trailer
            poster={seasonTrailer?.poster}
            src={seasonTrailer?.videoUrl}
            isFullScreen={isTrailerFullScreen}
            onPopupClose={toggleTrailer}
          />
        ) : (<div className="trailer-skeleton" />)}
        <div
          className={`trailer-overlay ${isTrailerFullScreen ? 'hidden' : ''}`}
          onClick={toggleTrailer}
          role="button"
          aria-hidden
        />
      </div>
      <div className="episode-cards-container">
        <EpisodeCards />
      </div>

      <div className="cast-members">
        <div className="cast-members-title-box">
          <h4 className="cast-members-title">Our suspects</h4>
        </div>
        {!!castList.length && (
          <CardsSlider
            cards={castList.map((castMember) => (
              <CastMemberCard
                key={castMember?.cardImage?.src}
                details={castMember}
              />
            ))}
            options={sliderOptions}
          />
        )}
      </div>
      <HomeFooter />
    </div>
  );
}

export default Home;
