import React, { useEffect, useState } from 'react';
import Episode from '../../api/services/Episode';
import EpisodeCard from '../EpisodeCard';
import CardsSlider from '../CardsSlider';

const sliderOptions = {
  perPage: 2,
  perMove: 1,
  padding: { left: 20 },
  breakpoints: {
    576: {
      perPage: 3,
    },
    768: {
      padding: { left: 60 },
    },
    992: {
      padding: { left: 80 },
      perPage: 4,
    },
    1200: {
      padding: { left: 100 },

    },
    1440: {
      padding: { left: 120 },
    },
    1600: {
      perPage: 5,
    },
  },
};

function EpisodeCards() {
  const [episodesInfo, setEpisodesInfo] = useState([]);

  useEffect(() => {
    Episode.getEpisodesPublicData().then((info) => {
      setEpisodesInfo(info);
    });
  }, []);

  if (!episodesInfo?.length) return null;

  return (
    <CardsSlider
      options={sliderOptions}
      cards={episodesInfo.map((episodeInfo) => (
        <EpisodeCard
          info={episodeInfo}
          key={`episode-card-${episodeInfo.id}`}
        />
      ))}
    />
  );
}

export default EpisodeCards;
