import React, { useEffect, useState } from 'react';

import apiConfig from '../../config/api-config';
import NavigationMenu from '../../components/NavigationMenu';
import Image from '../../components/Image';

import './FAQ.scss';

const ALLOWED_WRAPPER_TAGS = { b: 'b' };

function FAQ() {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    fetch(`https://${apiConfig.videoCDN}/assets/public/texts/faq.json`)
      .then((response) => response.json())
      .then((data) => {
        setSections(data);
      });
  }, []);

  return (
    <div className="faq-page">
      <NavigationMenu />
      <div className="faq-content">
        <div className="faq-paper-background">
          <div className="pin-box">
            <Image
              className="pin"
              src="/images/papers-and-pushpin/red-pushpin.png"
              alt="Pin"
              srcset={[
                { imageUrl: '/images/papers-and-pushpin/red-pushpin.webp' },
              ]}
            />
          </div>
          <article className="faq-article">
            <h1 className="faq-main-title">FAQ</h1>
            <p className="faq-article-paragraph">
              So you’ve got questions, eh, Rookie? Fine, we’ll give you some
              answers. But don’t get used to it - after this, you’re sleuthing
              on your own. Look sharp!
            </p>
            <ul className="faq-article-ul">
              {sections.map((section) => (
                <li className="faq-article-li" key={`faq-section-${section?.title}`}>
                  <p>{section.title}</p>
                  <p>{section.paragraph.content.map(formatContent)}</p>
                </li>
              ))}
            </ul>
          </article>
        </div>
      </div>
    </div>
  );
}

function formatContent(content) {
  switch (content.type) {
    case 'text':
      if (content.wrapperTags?.length) {
        return content.wrapperTags.reduce((result, wrapperTag) => {
          if (!Object.keys(ALLOWED_WRAPPER_TAGS).includes(wrapperTag)) {
            return result;
          }

          if (wrapperTag === ALLOWED_WRAPPER_TAGS.b) {
            return <b>{result}</b>;
          }

          return result;
        }, content.text);
      }
      return content.text;
    case 'link':
      return <a href={content.href}>{content.text}</a>;
    case 'mail':
      return <a href={`mailto:${content.text}`}>{content.text}</a>;
    default:
      return content.text;
  }
}

export default FAQ;
