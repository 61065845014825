/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { playerModes } from '../constants/playerModes';

const initialState = {
  isPlaying: false,
  isLive: true,
  isVideoEnded: false,
  isMuted: false,
  camerasPosition: {
    main: {},
    secondary: [],
  },
  mainCameraOptions: null,
  secondaryCameraOptions: null,
  roomsConfig: [],
  playedTime: 0,
  isMainCameraSwitchEnabled: true,
};

const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    play(state) {
      state.isPlaying = true;
    },
    pause(state) {
      state.isPlaying = false;
    },
    mute(state) {
      state.isMuted = true;
    },
    unmute(state) {
      state.isMuted = false;
    },
    togglePlay(state) {
      state.isPlaying = !state.isPlaying;
    },
    setPlayerMode(state, action) {
      state.isLive = action.payload !== playerModes.ON_DEMAND;
    },
    setVideoEnded(state) {
      state.isVideoEnded = true;
    },
    setCamerasPosition(state, action) {
      state.camerasPosition = action.payload;
    },
    setActiveCameraId(state, action) {
      const { roomId, activeCameraId } = action.payload;

      if (state.camerasPosition.main.roomId === roomId) {
        state.camerasPosition.main.activeCameraId = activeCameraId;
        return;
      }

      const secondaryCameraIndex = state.camerasPosition.secondary
        .findIndex((camera) => camera.roomId === roomId);

      state.camerasPosition.secondary[secondaryCameraIndex].activeCameraId = activeCameraId;
    },
    switchToMainCamera(state, action) {
      const { roomId } = action.payload;

      if (state.camerasPosition.main.roomId === roomId) {
        return;
      }

      const mainCameraState = state.camerasPosition.main;
      const secondaryCameraIndex = state.camerasPosition.secondary
        .findIndex((camera) => camera.roomId === roomId);

      state.camerasPosition.main = state.camerasPosition.secondary[secondaryCameraIndex];
      state.camerasPosition.secondary[secondaryCameraIndex] = mainCameraState;
    },
    setMainCameraOptions(state, action) {
      state.mainCameraOptions = action.payload;
    },
    setSecondaryCameraOptions(state, action) {
      state.secondaryCameraOptions = action.payload;
    },
    setRoomsConfig(state, action) {
      state.roomsConfig = action.payload;
    },
    setPlayedTime(state, action) {
      state.playedTime = action.payload;
    },
    disableMainCameraSwitch(state) {
      state.isMainCameraSwitchEnabled = false;
    },
    enableMainCameraSwitch(state) {
      state.isMainCameraSwitchEnabled = true;
    },
    resetPlayer: () => initialState,
  },
});

export const selectActiveCameraId = (state, roomId) => {
  const { camerasPosition: { main, secondary } } = state.player;
  if (main.roomId === roomId) {
    return main.activeCameraId;
  }

  const secondaryCameraIndex = secondary
    .findIndex((camera) => camera.roomId === roomId);

  return secondary[secondaryCameraIndex].activeCameraId;
};

export const selectCameras = (state, roomId, isMain) => {
  const config = state.player.roomsConfig.find((room) => room.roomId === roomId);

  return isMain ? config?.cameras.primary ?? [] : config?.cameras.secondary ?? [];
};

export const {
  play,
  pause,
  mute,
  unmute,
  togglePlay,
  setPlayerMode,
  setVideoEnded,
  setCamerasPosition,
  setActiveCameraId,
  switchToMainCamera,
  setMainCameraOptions,
  setSecondaryCameraOptions,
  setRoomsConfig,
  setPlayedTime,
  disableMainCameraSwitch,
  enableMainCameraSwitch,
  resetPlayer,
} = playerSlice.actions;

export default playerSlice.reducer;
