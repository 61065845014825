import React from 'react';
import PropTypes, { node } from 'prop-types';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './EvidenceSlider.scss';

const options = {
  perPage: 2,
  focus: 'center',
  gap: '1rem',
  trimSpace: false,
  mediaQuery: 'min',
  breakpoints: {
    1440: {
      perPage: 3,
    },
  },
};

function EvidenceSlider({ images }) {
  return (
    <Splide
      className="evidence-slider"
      options={{ ...options, preloadPages: images.length }}
    >
      {images.map((image, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <SplideSlide key={`evidence-slide-${index}`} data-testid="evidence-slide">{image}</SplideSlide>
      ))}
    </Splide>
  );
}
export default EvidenceSlider;

EvidenceSlider.propTypes = {
  images: PropTypes.arrayOf(node),
};

EvidenceSlider.defaultProps = {
  images: [],
};
