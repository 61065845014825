import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';

import { getFormattedEpisodeStartTime } from '../../../../utils/utils';
import Image from '../../../Image';

function PremiereCover({ coverImage, episodeStartTime }) {
  return (
    <div
      data-testid="premiere-cover-img"
      className="waiting-room-show-premiere-bg"
    >
      <Image
        src={coverImage?.src}
        srcset={coverImage?.srcset}
        alt="Premiere cover"
      />
      <div className="waiting-room-info-wrapper">
        <div className="waiting-room-info-data">
          <p className="title">Premiere on</p>
          <p className="date">
            {getFormattedEpisodeStartTime(episodeStartTime)}
          </p>
        </div>
      </div>
    </div>
  );
}

PremiereCover.propTypes = {
  coverImage: shape({
    src: string,
    srcset: arrayOf(
      shape({
        imageUrl: string,
        width: number,
      }),
    ),
  }).isRequired,
  episodeStartTime: string.isRequired,
};

export default PremiereCover;
