import { bool } from 'prop-types';
import React from 'react';
import { isMobile } from 'react-device-detect';

import { FIRST_TIME_GO_TO_DESKTOP_POPUP } from '../../constants/localStorageKeys';
import SimplePopup from '../SimplePopup';
import './MobileWarningPopup.scss';

const WARNING_MESSAGE = (
  <>
    <p>
      For a better experience, we strongly recommend using your desktop device.
    </p>
    <p>
      If you keep using your mobile device, please make sure to turn off Low power mode.
    </p>
    <p>
      Thank you!
    </p>
  </>
);

export function MobileWarningPopup({ isRepeat }) {
  const isFirstTime = (sessionStorage.getItem(FIRST_TIME_GO_TO_DESKTOP_POPUP) !== 'false');

  const onClose = () => {
    sessionStorage.setItem(FIRST_TIME_GO_TO_DESKTOP_POPUP, 'false');
  };

  // popup should be displayed only on mobile, on the first time
  if (isMobile) {
    if (isFirstTime || isRepeat) {
      return <SimplePopup text={WARNING_MESSAGE} onClose={onClose} />;
    }
  }
  return null;
}

MobileWarningPopup.propTypes = {
  isRepeat: bool,
};
MobileWarningPopup.defaultProps = {
  isRepeat: false,
};

export default MobileWarningPopup;
