import { getElapsedSeconds } from '../../services/TimeSync';
import SyncStrategy from '../SyncStrategy/SyncStrategy';

export default class SyncWithCountdownElapsedTimeStrategy extends SyncStrategy {
  constructor(videoDurationSec) {
    super();
    this.videoDuration = videoDurationSec;
  }

  getTimeToSyncWith() {
    const elapsedSeconds = getElapsedSeconds();
    return this.videoDuration + elapsedSeconds;
  }
}
