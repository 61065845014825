import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useEpisodeActivationData from '../../hooks/useEpisodeActivationData';
import usePremiereSettings from '../../hooks/usePremiereSettings';
import useEpisodeData from '../../hooks/useEpisodeData';

import { setOpenedTabId } from '../../utils/tab-utils';
import { resetPlayer, setPlayerMode } from '../../reducers/player';

import { episodeModes } from '../../constants/episodeModes';
import { playerModes } from '../../constants/playerModes';
import { SEASON_ID } from '../../constants';

import { useEpisodeVideoData } from '../../components/EpisodePlayer/hooks/useEpisodeVideoData';
import { useTabDetection } from '../../components/EpisodePlayer/hooks/useTabDetection';
import { usePolling } from '../../components/EpisodePlayer/hooks/usePolling';
import EpisodePlayerTopMenu from '../../components/EpisodePlayerTopMenu';
import MobileWarningPopup from '../../components/MobileWarningPopup';
import EpisodePlayer from '../../components/EpisodePlayer';
import WaitingRoom from '../../components/WaitingRoom';
import SimplePopup from '../../components/SimplePopup';

import './EpisodeWatch.scss';

function EpisodeWatch() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { episodeId } = useParams();
  const EPISODE_ID = SEASON_ID + episodeId;
  const [isEpisodeShown, setIsEpisodeShown] = useState(false);
  const [isTimeToShowEpisode, setIsTimeToShowEpisode] = useState(false);
  const [preloadVideoLinks, setPreloadVideoLinks] = useState(false);
  const { episodeInfo } = useEpisodeData(EPISODE_ID);

  const isEpisodeActivated = useEpisodeActivationData(EPISODE_ID, SEASON_ID);
  const isLiveMode = useSelector((state) => state.player.isLive);

  const { isMultipleWatching, isTicketSessionEnded } = usePolling(EPISODE_ID, SEASON_ID);
  const { anotherTabDetected } = useTabDetection();
  const isSessionInvalid = isMultipleWatching || anotherTabDetected || isTicketSessionEnded;

  useEffect(() => {
    setOpenedTabId();

    return () => {
      dispatch(resetPlayer());
    };
  }, []);

  useEffect(() => {
    if (isEpisodeActivated === null) return;

    if (isEpisodeActivated) {
      setIsEpisodeShown(true);
    } else {
      navigate(`/episode/${episodeId}`);
    }
  }, [isEpisodeActivated]);

  useEpisodeVideoData(EPISODE_ID, SEASON_ID, isLiveMode, preloadVideoLinks);

  const { mode, shouldPreparePlayers } = usePremiereSettings({
    episodeStartTime: episodeInfo?.startTime,
    episodeEndTime: episodeInfo?.endTime,
    showCountdownBeforeHr: episodeInfo?.premiereConfig.showCountdownBeforeHr,
    countdownVideoDurationMin:
      episodeInfo?.premiereConfig.waitingRoom.countdownVideoDurationMin,
    forceGetCurrentTimeRequest: true,
  });

  useEffect(() => {
    setPreloadVideoLinks(
      mode === episodeModes.WAITING_ROOM
      || mode === episodeModes.LIVE
      || mode === episodeModes.ON_DEMAND,
    );

    setIsTimeToShowEpisode(
      mode === episodeModes.LIVE || mode === episodeModes.ON_DEMAND,
    );

    switch (mode) {
      case episodeModes.LIVE:
        dispatch(setPlayerMode(playerModes.LIVE));
        break;
      case episodeModes.ON_DEMAND:
        dispatch(setPlayerMode(playerModes.ON_DEMAND));
        break;
      default:
        dispatch(setPlayerMode(playerModes.NOT_STARTED));
    }
  }, [mode]);

  if (!isEpisodeShown) return null;

  const isWaitingRoomToShow = isLiveMode && !isTimeToShowEpisode && episodeInfo && mode;
  const isEpisodePlayerToShow = !isSessionInvalid && (isTimeToShowEpisode || !isLiveMode);
  const isEpisodePlayerToShowInBackground = !isSessionInvalid && shouldPreparePlayers;
  const isMuteButtonToShow = !!(isWaitingRoomToShow || (isEpisodePlayerToShow && isLiveMode));

  return (
    <div>
      <EpisodePlayerTopMenu
        showEvidenceButton={isTimeToShowEpisode}
        showMuteButton={isMuteButtonToShow}
        episodeId={EPISODE_ID}
      />
      {(isEpisodePlayerToShow || isEpisodePlayerToShowInBackground) && (
        <EpisodePlayer
          episodeId={EPISODE_ID}
          seasonId={SEASON_ID}
          allowLivePlay={isEpisodePlayerToShow}
        />
      )}
      {isWaitingRoomToShow && (
        <WaitingRoom episodeInfo={episodeInfo} episodeMode={mode} />
      )}
      <MobileWarningPopup isRepeat />
      <InvalidSessionWarning
        isMultipleWatching={isMultipleWatching}
        anotherTabDetected={anotherTabDetected}
        isTicketSessionEnded={isTicketSessionEnded}
      />
    </div>
  );
}

function InvalidSessionWarning({
  // eslint-disable-next-line react/prop-types
  isMultipleWatching, anotherTabDetected, isTicketSessionEnded,
}) {
  const navigate = useNavigate();
  const { episodeId } = useParams();

  if (!(isMultipleWatching || anotherTabDetected || isTicketSessionEnded)) return null;

  return (
    <SimplePopup
      text={(() => {
        if (isTicketSessionEnded) return 'Ticket expired. Please re-submit the ticket code.';
        if (isMultipleWatching || anotherTabDetected) return 'The video cannot be displayed. Multiple connections per ticket are detected.';
        return 'Unknown error.';
      })()}
      onClose={() => navigate(`/episode/${episodeId}`)}
    />
  );
}

export default EpisodeWatch;
