import { useState } from 'react';
import { useInterval } from 'react-use';

export default function useCountdown(timeToStart, endBefore) {
  const [countdownTime, setCountdownTime] = useState(timeToStart);
  const [countdownDelay, setCountdownDelay] = useState(1000);

  useInterval(() => {
    // TODO fix 04:59
    setCountdownTime((prevTimeToStart) => {
      if (prevTimeToStart <= endBefore) {
        setCountdownDelay(null);
        return 0;
      }
      return prevTimeToStart - 1000;
    });
  }, countdownDelay);

  return countdownTime;
}
