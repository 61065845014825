import React from 'react';
import { useParams } from 'react-router-dom';

import NavigationMenu from '../../components/NavigationMenu';
import EpisodeInfo from '../../components/EpisodeInfo';
import { SEASON_ID } from '../../constants';

import './Episode.scss';

function Episode() {
  const { episodeId } = useParams();
  const EPISODE_ID = SEASON_ID + episodeId;

  return (
    <div className="episode-info-page">
      <NavigationMenu />
      <EpisodeInfo episodeId={EPISODE_ID} />
    </div>
  );
}

export default Episode;
